import React from 'react';
import RenderRegistry from '../common/RenderRegistry';
import { WidthProvider, Responsive } from 'react-grid-layout';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import StateHelper from 'libs/stateHelper';
import crud from 'libs/crud';
import { v4 as uuidv4 } from 'uuid';
import $storage from 'libs/storage';
import path from 'path';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useModal } from 'components/Modal';
import $config from '../../config/config';

const cx = new StateHelper();

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DefaultContent = (props) => {
  const { index, context, isEdit, state } = props;
  const options = [
    { label: 'File', value: 'file' },
    { label: 'File (Upload Only)', value: 'file-upload' },
    { label: 'Link', value: 'link' },
    { label: 'Text', value: 'text' },
  ];

  const handleChange = (event) => {
    const idx = options.findIndex((o) => o.value === event.target.value);
    if (event.target.value == 'file') {
      context.setState({
        [`${index}.type`]: event.target.value,
        [`${index}.type_`]: options[idx],
        [`${index}.file`]: [],
      });
      return;
    }
    context.setState({
      [`${index}.type`]: event.target.value,
      [`${index}.type_`]: options[idx],
    });
  };

  const onUploadSuccess = (files) => {
    if (files.length > 0) {
      setTimeout(() => {
        context.setState({
          [`${index}.file`]: files[0],
        });
        const d = crud('downloadables');
        d.save({
          title: state.fileTitle || '',
          requirementId: index,
          file: files,
          file_: files[0],
        });
      }, 50);
    }
  };

  let base = 'downloadables';

  const upload = (files) => {
    let p = [];
    Array.prototype.forEach.call(files || [], (file) => {
      p.push(
        $storage.upload(file, {
          path: path.join(base || '', file.name),
          // path: 'tmp/' + file.name,
          onUploadProgress: (p) => {
            console.log(file.name);
            console.log(p);
          },
        })
      );
    });

    Promise.all(p).then((res) => {
      let files = res.map((f) => {
        if (!f || !f.data) {
          return '';
        }
        return f.data.file;
      });

      onUploadSuccess(files);
    });
  };

  const onFileChange = (evt) => {
    upload(evt.target.files);
  };

  return (
    <div
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      {isEdit ? (
        <React.Fragment>
          <div style={{ padding: '0.3em 0' }}>
            <TextField
              {...context.model(`${index}.title`)}
              label="Title"
              variant="outlined"
              fullWidth
            />
          </div>
          <div style={{ padding: '0.3em 0' }}>
            <TextField
              {...context.model(`${index}.description`)}
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={8}
            />
          </div>
          <div style={{ padding: '0.3em 0' }}>
            <TextField
              {...context.model(`${index}.details`)}
              label="Details"
              variant="outlined"
              fullWidth
              multiline
              rows={8}
            />
          </div>
          <div style={{ padding: '0.3em 0' }}>
            <TextField
              label="Type"
              fullWidth
              variant="outlined"
              onChange={handleChange}
              value={state.type}
              select
              size="small"
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div>
            {state.type == 'file' && (
              <div style={{ padding: '0.3em 0' }}>
                <TextField
                  {...context.model(`${index}.fileTitle`)}
                  label="Name of Downloadable"
                  variant="outlined"
                  fullWidth
                  size="small"
                  style={{ margin: '0.3em 0' }}
                />
                <input
                  style={{ display: 'none' }}
                  id={`${index}-downloables-file`}
                  type="file"
                  onChange={onFileChange}
                />
                <label htmlFor={`${index}-downloables-file`}>
                  <Button
                    disabled={!state.fileTitle}
                    variant="contained"
                    color="primary"
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
                {state.file && (
                  <Typography style={{ margin: '0.5em 0' }}>
                    <InsertDriveFileIcon /> {path.basename(state.file.name)}
                  </Typography>
                )}
              </div>
            )}

            {state.type == 'link' && (
              <div style={{ padding: '0.3em 0' }}>
                <TextField
                  {...context.model(`${index}.link`)}
                  label="Hyperlink"
                  variant="outlined"
                  fullWidth
                />
              </div>
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography variant={'h4'} style={{ padding: '0.25em 0' }}>
            {state.title}
          </Typography>
          <Typography style={{ padding: '0.25em 0' }}>
            {state.description}
          </Typography>
          <Typography style={{ padding: '0.25em 0' }}>
            {state.details}
          </Typography>
          {state.type_ && (
            <Typography style={{ padding: '0.25em 0' }}>
              {state.type_.label}
            </Typography>
          )}
          {state.type == 'file' && state.file && (
            <Typography style={{ padding: '0.25em 0' }}>
              <InsertDriveFileIcon /> {path.basename(state.file.name)}
            </Typography>
          )}

          {state.type == 'link' && (
            <a target="_blank" href={state.link}>
              <Typography style={{ padding: '0.25em 0' }}>
                {state.link}
              </Typography>
            </a>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const RequirementsGrid = (props) => {
  const [layout, setLayout] = React.useState([
    {
      i: 'n1',
      x: 0,
      y: 0,
      w: 1,
      h: 5,
      static: true,
    },
  ]);
  const [state, setState] = React.useState({ n1: {} });
  const [isEdit, setIsEdit] = React.useState(false);
  const [settingsId, setSettingsId] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const { showModal, closeModal } = useModal();

  const s = crud('settings');

  cx.useState(state, setState);

  const onLayoutChange = (l) => {
    setLayout(l);
  };

  const deleteGrid = (index) => {
    let _layout = layout.filter((lay) => lay.i !== index);
    const _state = state;
    delete _state[index];
    setLayout([..._layout]);
    setState({ ..._state });
  };

  const addNewGrid = () => {
    const _layout = layout || [];
    const l = {
      i: uuidv4(),
      x: layout.length % 3,
      // x: 4,
      y: Infinity,
      w: 1,
      h: 5,
    };
    _layout.push(l);
    setLayout([..._layout]);
    setState({ ...state, [`${l.i}`]: {} });
  };

  const ex = {
    cols: { lg: 3, md: 3, sm: 3, xs: 3, xxs: 3 },
    rowHeight: 100,
    isBounded: true,
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  };

  const onSave = async () => {
    setIsLoading(true);
    let doc = {
      key: 'requirements',
      data: '',
      json: {
        layout: [...layout],
        state: { ...state },
      },
    };

    if (settingsId !== 0) {
      doc._id = settingsId;
    }

    const res = await s.save(doc);
    retrieve();
    setIsEdit(false);
    setIsLoading(false);
  };

  const retrieve = async () => {
    setIsLoading(true);
    const res = await s.find({
      key: 'requirements',
    });

    if (res) {
      if (res.data.length > 0) {
        setSettingsId(res.data[0]._id);
        setLayout([...res.data[0].json.layout]);
        setState({ ...res.data[0].json.state });
      }
    }
    setIsLoading(false);
  };

  const PasswordComponent = (props) => {
    const _password = $config.app.passwords.requirements || 'mabuhay2021';
    const [password, setPassword] = React.useState({ error: false });

    const handleChange = (event) => {
      setPassword({ ...password, data: event.target.value, error: false });
    };

    const onSubmit = () => {
      if (_password !== password.data) {
        setPassword({ error: true });
        return;
      }

      props.onSuccess();
      props.closeModal();
    };

    return (
      <div style={{ padding: '0 1em' }}>
        <TextField
          onChange={handleChange}
          variant="filled"
          label="Password"
          type="password"
          error={password.error}
          helperText={password.error ? 'Password is incorrect.' : ''}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '1em',
          }}
        >
          <Button
            onClick={() => {
              props.closeModal();
            }}
          >
            Cancel
          </Button>
          <Button onClick={onSubmit}>Submit</Button>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    retrieve();
  }, []);

  return (
    <div>
      <Typography variant="h3">Requirements</Typography>
      {isLoading ? (
        <CircularProgress />
      ) : isEdit ? (
        <div>
          <Button style={{ margin: '1em 0' }} onClick={onSave}>
            Save
          </Button>
          <Button style={{ margin: '1em 0' }} onClick={addNewGrid}>
            New Item
          </Button>
        </div>
      ) : (
        <Button
          style={{ margin: '1em 0' }}
          onClick={() => {
            showModal({
              title: 'Enter Password',
              component: PasswordComponent,
              componentProps: {
                closeModal: closeModal,
                onSuccess: () => {
                  setIsEdit(true);
                },
              },
            });
            // setIsEdit(true);
          }}
        >
          Edit
        </Button>
      )}
      <ResponsiveReactGridLayout
        isDraggable={isEdit}
        className="layout"
        {...ex}
        isResizable={false}
        onLayoutChange={onLayoutChange}
      >
        {layout.map((l) => (
          <div data-grid={l} key={l.i} style={{ cursor: 'grab' }}>
            <Paper className="grid-layout-item" elevation={3}>
              <div className="grid-layout-form">
                {l.i === 'n1' ? (
                  <React.Fragment>
                    <Typography
                      variant="h2"
                      style={{ padding: '0.25em 0', textAlign: 'center' }}
                    >
                      Applicant's Basic Information
                    </Typography>
                  </React.Fragment>
                ) : state[l.i] ? (
                  <DefaultContent
                    context={cx}
                    index={l.i}
                    isEdit={isEdit}
                    state={state[l.i]}
                  />
                ) : null}
                {isEdit && l.i !== 'n1' && (
                  <div
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                    style={{ padding: '1em 0' }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        deleteGrid(l.i);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
            </Paper>
          </div>
        ))}
      </ResponsiveReactGridLayout>

      {/* <pre>{JSON.stringify(layout, null, 4)}</pre> */}
      {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
    </div>
  );
};

RenderRegistry.add({
  RequirementsGrid,
});
