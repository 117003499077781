import React from 'react';
import RenderRegistry from '../RenderRegistry';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import crud from 'libs/crud';
import cache from 'libs/cache.js';
import { useApp } from 'stores/AppStore';

const TermsAndConditions = (props) => {
  const app = useApp();
  const [checked, setChecked] = React.useState(false);
  const [content, setContent] = React.useState('');

  const { node } = props;
  const cls = props.node.className;
  const children = node.children;
  const terms = crud('terms-conditions');

  let fs = props.context;

  if (!fs._state.data.id) {
    if (
      fs._state['terms-conditions'] === undefined &&
      app.state.activeAppName !== 'dashboard'
    ) {
      fs.setState({ 'terms-conditions': false });
    }
  }

  const loadTerms = async () => {
    let _terms = cache.get('terms-conditions', []);

    try {
      const t = await terms.find({});

      if (t.data.length > 0) {
        _terms = t.data;
        cache.put('terms-conditions', t.data, {
          persist: true,
        });
      }
    } catch (err) {}

    const idx = _terms.findIndex((x) => x.name === node.name);

    if (idx < 0) {
      setContent('Terms and Conditions not found.');
      return;
    }

    setContent(_terms[idx].content);
  };

  React.useEffect(() => {
    loadTerms();
  }, []);

  const handleChange = (event) => {
    fs.setState({ 'terms-conditions': !fs._state['terms-conditions'] });
  };

  return (
    <div>
      {fs._state.data.id === undefined &&
        app.state.activeAppName !== 'dashboard' && (
          <div>
            <div className="form-label">
              <Typography className="mt-2" variant="h6">
                Terms and Conditions
              </Typography>
            </div>
            <div
              className="terms-conditions-list p-2"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fs._state['terms-conditions'] || false}
                  onChange={handleChange}
                  name="TermsAndConditions"
                  color="primary"
                />
              }
              label={
                node.label ? (
                  node.label
                ) : (
                  <span>
                    I have read and accept the <b>Terms and Conditions</b>.
                  </span>
                )
              }
            />
          </div>
        )}
    </div>
  );
};

RenderRegistry.add({
  TermsAndConditions,
});
