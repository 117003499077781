import AccountBalance from '@material-ui/icons/AccountBalance';
import Add from '@material-ui/icons/Add';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import Brush from '@material-ui/icons/Brush';
import Business from '@material-ui/icons/Business';
import CalendarToday from '@material-ui/icons/CalendarToday';
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber';
import Code from '@material-ui/icons/Code';
import Dashboard from '@material-ui/icons/Dashboard';
import Delete from '@material-ui/icons/Delete';
import LocalAtm from '@material-ui/icons/LocalAtm';
import People from '@material-ui/icons/People';
import Sort from '@material-ui/icons/Sort';
import Today from '@material-ui/icons/Today';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import ViewList from '@material-ui/icons/ViewList';
import Visibility from '@material-ui/icons/Visibility';
import AddCircle from '@material-ui/icons/AddCircle';
import Panorama from '@material-ui/icons/Panorama';
import Person from '@material-ui/icons/Person';
import Edit from '@material-ui/icons/Edit';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Save from '@material-ui/icons/Save';
import Print from '@material-ui/icons/Print';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Publish from '@material-ui/icons/Publish';

export const library = {
  add: (icon, name) => {
    library['mui' + name] = icon;
  },
};

library.add(AccountBalance, 'AccountBalanceIcon');
library.add(Add, 'Add');
library.add(AddAPhoto, 'AddAPhoto');
library.add(ArrowBack, 'ArrowBack');
library.add(ArrowDownward, 'ArrowDownward');
library.add(ArrowForward, 'ArrowForward');
library.add(ArrowUpward, 'ArrowUpward');
library.add(AssignmentInd, 'AssignmentInd');
library.add(Brush, 'BrushIcon');
library.add(Business, 'Business');
library.add(CalendarToday, 'CalendarToday');
library.add(ConfirmationNumber, 'ConfirmationNumber');
library.add(Code, 'Code');
library.add(Dashboard, 'Dashboard');
library.add(Delete, 'Delete');
library.add(LocalAtm, 'LocalAtm');
library.add(People, 'People');
library.add(Sort, 'Sort');
library.add(Today, 'Today');
library.add(TransferWithinAStation, 'TransferWithinAStation');
library.add(ViewList, 'ViewList');
library.add(Visibility, 'Visibility');
library.add(AddCircle, 'AddCircle');
library.add(Panorama, 'Panorama');
library.add(Person, 'Person');
library.add(Edit, 'Edit');
library.add(SaveAlt, 'SaveAlt');
library.add(Save, 'Save');
library.add(Print, 'Print');
library.add(VisibilityOff, 'VisibilityOff');
library.add(Publish, 'Publish');
