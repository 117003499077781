import React from 'react';
import RenderRegistry from '../common/RenderRegistry';
import { WidthProvider, Responsive } from 'react-grid-layout';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import StateHelper from 'libs/stateHelper';
import Grid from '@material-ui/core/Grid';
import crud from 'libs/crud';
import { v4 as uuidv4 } from 'uuid';
import { useModal } from 'components/Modal';
import $config from '../../config/config';

const cx = new StateHelper();

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DefaultContent = (props) => {
  const { index, context, isEdit, state } = props;
  const options = [
    { label: 'Short Answer', value: 'short-answer' },
    { label: 'Paragraph', value: 'paragraph' },
    { label: 'Multiple Choice', value: 'multiple-choice' },
    { label: 'Linear Scale', value: 'linear-scale' },
  ];

  const handleChangeObject = (event, model) => {
    const idx = options.findIndex((o) => o.value === event.target.value);
    context.setState({
      [`${index}.${model}`]: event.target.value,
      [`${index}.${model}_`]: options[idx],
    });
  };

  const handleChange = (event, model) => {
    context.setState({
      [`${index}.${model}`]: event.target.value,
    });
  };

  const addOption = () => {
    const opts = state.options || [];
    opts.push({
      label: '',
      value: uuidv4(),
      points: '',
      comments: '',
    });
    context.setState({
      [`${index}.options`]: [...opts],
    });
  };

  const delOption = (value) => {
    const opts = state.options.filter((lay) => lay.value !== value);
    context.setState({
      [`${index}.options`]: [...opts],
    });
  };

  return (
    <div
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      {isEdit ? (
        <React.Fragment>
          <div style={{ padding: '0.3em 0' }}>
            <TextField
              {...context.model(`${index}.question`)}
              label="Question"
              variant="outlined"
              fullWidth
            />
          </div>
          <div style={{ padding: '0.3em 0' }}>
            <TextField
              label="Type"
              fullWidth
              variant="outlined"
              onChange={(e) => handleChangeObject(e, 'type')}
              value={state.type}
              select
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div>
            {state.type == 'multiple-choice' && (
              <React.Fragment>
                <Typography variant="body2">Options</Typography>
                {state.options &&
                  state.options.map((o, idx) => (
                    <React.Fragment key={idx}>
                      <div style={{ padding: '0.5em 0', width: '80%' }}>
                        <Grid container>
                          <Grid style={{ padding: '0 0.3em' }} md={8} item>
                            <TextField
                              {...context.model(
                                `${index}.options.${idx}.label`
                              )}
                              label="Label"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid style={{ padding: '0 0.3em' }} md={4} item>
                            <TextField
                              {...context.model(
                                `${index}.options.${idx}.points`
                              )}
                              label="Points"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid style={{ padding: '0.3em 0.3em' }} md={11} item>
                            <TextField
                              {...context.model(
                                `${index}.options.${idx}.comments`
                              )}
                              label="Comments"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={4}
                            />
                          </Grid>
                          <Grid style={{ padding: '0.3em 0.3em' }} md={1} item>
                            <Button
                              onClick={() => {
                                delOption(o.value);
                              }}
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </React.Fragment>
                  ))}
                <div style={{ padding: '0.3em 0' }}>
                  <Button onClick={addOption}>Add Option</Button>
                </div>
              </React.Fragment>
            )}
            {state.type == 'linear-scale' && (
              <React.Fragment>
                <Grid container>
                  <Grid style={{ padding: '0.3em 0' }} md={2} item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      onChange={(e) => handleChange(e, 'from')}
                      value={state.from}
                      select
                    >
                      {[0, 1].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    style={{
                      padding: '0 0.3em',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    md={1}
                    item
                  >
                    to
                  </Grid>
                  <Grid style={{ padding: '0.3em 0' }} md={2} item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      onChange={(e) => handleChange(e, 'to')}
                      value={state.to}
                      select
                    >
                      {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid md={3} item>
                    <TextField
                      {...context.model(`${index}.fromLabel`)}
                      label="From Label"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid style={{ paddingLeft: '0.3em' }} md={3} item>
                    <TextField
                      {...context.model(`${index}.toLabel`)}
                      label="To Label"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Typography variant="body2">Comments</Typography>
                <Grid container>
                  <Grid md={3} style={{ padding: '0.3em 0.2em' }} item>
                    <TextField
                      {...context.model(`${index}.fromComments`)}
                      label="From"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid md={3} style={{ padding: '0.3em 0.2em' }} item>
                    <TextField
                      {...context.model(`${index}.betweenComments`)}
                      label="In-between"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid md={3} style={{ padding: '0.3em 0.2em' }} item>
                    <TextField
                      {...context.model(`${index}.toComments`)}
                      label="To"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography variant={'h4'} style={{ padding: '0.25em 0' }}>
            {state.question}
          </Typography>
          <Typography style={{ padding: '0.25em 0' }}>
            {state.description}
          </Typography>
          <Typography style={{ padding: '0.25em 0' }}>
            {state.details}
          </Typography>
          {state.type_ && (
            <Typography style={{ padding: '0.25em 0' }}>
              {state.type_.label}
            </Typography>
          )}
          {state.type == 'multiple-choice' &&
            state.options &&
            state.options.map((o, idx) => (
              <React.Fragment key={idx}>
                <div style={{ padding: '0.3em 0', width: '80%' }}>
                  <Typography>
                    {o.label}, <b>{o.points}</b>
                  </Typography>
                </div>
              </React.Fragment>
            ))}
          {state.type == 'linear-scale' && (
            <Typography>
              {state.from} ({state.fromLabel}) to {state.to} ({state.toLabel})
            </Typography>
          )}

          {state.type == 'link' && (
            <a target="_blank" href={state.link}>
              <Typography style={{ padding: '0.25em 0' }}>
                {state.link}
              </Typography>
            </a>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const QuestionnaireGrid = (props) => {
  const [layout, setLayout] = React.useState([]);
  const [state, setState] = React.useState({ n1: {} });
  const [isEdit, setIsEdit] = React.useState(false);
  const [settingsId, setSettingsId] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const { showModal, closeModal } = useModal();
  const gridEndRef = React.useRef(null);

  const s = crud('settings');

  cx.useState(state, setState);

  const onLayoutChange = (l) => {
    setLayout(l);
  };

  const deleteGrid = (index) => {
    let _layout = layout.filter((lay) => lay.i !== index);
    const _state = state;
    delete _state[index];
    setLayout([..._layout]);
    setState({ ..._state });
  };

  const addNewGrid = () => {
    const _layout = layout || [];
    const l = {
      i: uuidv4(),
      x: layout.length % 1,
      // x: 4,
      y: Infinity,
      w: 1,
      h: 1,
      autoHeight: true,
      minH: 1,
      maxH: Infinity,
    };
    _layout.push(l);
    setLayout([..._layout]);
    setState({ ...state, [`${l.i}`]: {} });
    setTimeout(() => {
      if (gridEndRef.current) {
        gridEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  };

  const ex = {
    cols: { lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 },
    isBounded: true,
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    rowHeight: 400,
  };

  const onSave = async () => {
    setIsLoading(true);
    let doc = {
      key: 'questionnaire',
      data: '',
      json: {
        layout: [...layout],
        state: { ...state },
      },
    };

    if (settingsId !== 0) {
      doc._id = settingsId;
    }

    const res = await s.save(doc);
    retrieve();
    setIsEdit(false);
    setIsLoading(false);
  };

  const retrieve = async () => {
    const res = await s.find({
      key: 'questionnaire',
    });

    if (res) {
      if (res.data.length > 0) {
        setSettingsId(res.data[0]._id);
        setLayout([...res.data[0].json.layout]);
        setState({ ...res.data[0].json.state });
      }
    }
  };

  const PasswordComponent = (props) => {
    const _password = $config.app.passwords.questionnaire || 'mabuhay2021';
    const [password, setPassword] = React.useState({ error: false });

    const handleChange = (event) => {
      setPassword({ ...password, data: event.target.value, error: false });
    };

    const onSubmit = () => {
      if (_password !== password.data) {
        setPassword({ error: true });
        return;
      }

      props.onSuccess();
      props.closeModal();
    };

    return (
      <div style={{ padding: '0 1em' }}>
        <TextField
          onChange={handleChange}
          variant="filled"
          label="Password"
          type="password"
          error={password.error}
          helperText={password.error ? 'Password is incorrect.' : ''}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '1em',
          }}
        >
          <Button
            onClick={() => {
              props.closeModal();
            }}
          >
            Cancel
          </Button>
          <Button onClick={onSubmit}>Submit</Button>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    retrieve();
  }, []);

  return (
    <div>
      <Typography variant="h3">Questionnaire</Typography>
      {isLoading ? (
        <CircularProgress />
      ) : isEdit ? (
        <div>
          <Button style={{ margin: '1em 0' }} onClick={onSave}>
            Save
          </Button>
          <Button style={{ margin: '1em 0' }} onClick={addNewGrid}>
            New Item
          </Button>
        </div>
      ) : (
        <Button
          style={{ margin: '1em 0' }}
          onClick={() => {
            showModal({
              title: 'Enter Password',
              component: PasswordComponent,
              componentProps: {
                closeModal: closeModal,
                onSuccess: () => {
                  setIsEdit(true);
                },
              },
            });
          }}
        >
          Edit
        </Button>
      )}
      <ResponsiveReactGridLayout
        isDraggable={isEdit}
        className="layout"
        {...ex}
        isResizable={false}
        onLayoutChange={onLayoutChange}
      >
        {layout.map((l, idx) => (
          <div data-grid={l} key={l.i} style={{ cursor: 'grab' }}>
            <Paper className="grid-layout-item" elevation={3}>
              <div className="grid-layout-form">
                {state[l.i] ? (
                  <DefaultContent
                    context={cx}
                    index={l.i}
                    isEdit={isEdit}
                    state={state[l.i]}
                  />
                ) : null}
                {isEdit && (
                  <div
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                    style={{ padding: '1em 0' }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        deleteGrid(l.i);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
            </Paper>
          </div>
        ))}
      </ResponsiveReactGridLayout>
      <div ref={gridEndRef}></div>
      {/* <pre>{JSON.stringify(layout, null, 4)}</pre>
      <pre>{JSON.stringify(state, null, 4)}</pre> */}
    </div>
  );
};

RenderRegistry.add({
  QuestionnaireGrid,
});
