import React from 'react';
import StateHelper from 'libs/stateHelper';
import RenderRegistry from '../RenderRegistry';
import events from 'libs/events';
import { withRouter } from 'react-router-dom';

import $http from 'libs/http';
import config from 'config/config';
import { useApp } from 'stores/AppStore';
import { useSnackbar } from 'notistack';
import { isRequired } from 'libs/validators';
import crud from 'libs/crud';

const cx = new StateHelper();

const ChangePassword = withRouter((props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { node, history } = props;
  const [state, setState] = React.useState({
    data: {
      oldPassword: '',
      password: '',
    },
  });

  cx.useState(state, setState);
  cx.useValidator({
    data: {
      oldPassword: {
        isRequired,
      },
      password: {
        isRequired,
      },
    },
  });

  const onChangePassword = async () => {
    await cx.validateStateWithWait();
    if (cx.hasErrors()) {
      enqueueSnackbar('Please fill up all required field.', {
        variant: 'error',
      });
      return;
    }

    let res = {};

    try {
      res = await $http({
        method: 'PUT',
        url: config.app.server.url + `/auth-local/update`,
        data: {
          ...state.data,
        },
      });
      if (res.data) {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: 'error' });
        }

        if (res.data.message) {
          history.push('/account');
          enqueueSnackbar('Password changed successfully.', {
            variant: 'success',
          });
        }
      }
    } catch (err) {
      res.data = {};
    }
  };

  const componentEvents = {
    change_password: onChangePassword,
  };

  React.useEffect(() => {
    events.register(componentEvents);
    return () => {
      events.unregister(componentEvents);
    };
  });

  return (
    <div>
      {RenderRegistry.renderChildren(node.children, {
        ...props,
        context: cx,
      })}
    </div>
  );
});

const ForgotPassword = withRouter((props) => {
  const app = useApp();
  const { enqueueSnackbar } = useSnackbar();
  const { node, history } = props;
  const [state, setState] = React.useState({
    data: {
      email: '',
    },
  });

  cx.useState(state, setState);
  cx.useValidator({
    data: {
      email: {
        isRequired,
      },
    },
  });

  const onSubmit = async () => {
    await cx.validateStateWithWait();
    if (cx.hasErrors()) {
      enqueueSnackbar('Please fill up all required field.', {
        variant: 'error',
      });
      return;
    }

    let res = {};

    try {
      res = await $http({
        method: 'POST',
        url: config.app.server.url + `/auth/forgot-password`,
        data: {
          ...state.data,
        },
      });
      if (res.data) {
        history.push(`/${app.state.activeAppName}-login`);
        enqueueSnackbar('Email Sent.', { variant: 'success' });
      }
    } catch (error) {
      console.log(error.response.data.data);
      let msg = 'Something went wrong';

      if (error.response.data) {
        if (error.response.data.data.length > 0) {
          msg = error.response.data.data[0].messages[0].message;
        }
      }

      enqueueSnackbar(msg, { variant: 'error' });
    }
  };

  const componentEvents = {
    forgot_password: onSubmit,
  };

  React.useEffect(() => {
    events.register(componentEvents);
    return () => {
      events.unregister(componentEvents);
    };
  });

  return (
    <div>
      {RenderRegistry.renderChildren(node.children, {
        ...props,
        context: cx,
      })}
    </div>
  );
});

const ResetPassword = withRouter((props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { node, history } = props;

  const params = new URLSearchParams(props.location.search);
  const code = params.get('code');

  if (!code) {
    history.push('/');
  }

  const [state, setState] = React.useState({
    data: {
      password: '',
      passwordConfirmation: '',
      code: code,
    },
  });

  cx.useState(state, setState);
  cx.useValidator({
    data: {
      password: {
        isRequired,
      },
    },
  });

  const onSubmit = async () => {
    await cx.validateStateWithWait();
    if (cx.hasErrors()) {
      enqueueSnackbar('Please fill up all required field.', {
        variant: 'error',
      });
      return;
    }

    if (state.data.password !== state.data.passwordConfirmation) {
      enqueueSnackbar('Passwords does not match.', {
        variant: 'error',
      });
      return;
    }

    let res = {};

    let d = { ...state.data };

    try {
      res = await $http({
        method: 'POST',
        url: config.app.server.url + `/auth/reset-password`,
        data: d,
      });
      if (res.data) {
        history.push('/');
        enqueueSnackbar('Password changed successfully.', {
          variant: 'success',
        });
      }
    } catch (error) {
      console.log(error.response.data.data);
      let msg = 'Something went wrong';

      if (error.response.data) {
        if (error.response.data.data.length > 0) {
          msg = error.response.data.data[0].messages[0].message;
        }
      }

      enqueueSnackbar(msg, { variant: 'error' });
    }
  };

  const componentEvents = {
    reset_password: onSubmit,
  };

  React.useEffect(() => {
    events.register(componentEvents);
    return () => {
      events.unregister(componentEvents);
    };
  });

  return (
    <div>
      {RenderRegistry.renderChildren(node.children, {
        ...props,
        context: cx,
      })}
    </div>
  );
});

RenderRegistry.add({
  ChangePassword,
  ResetPassword,
  ForgotPassword,
});
