import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'components/icons/Icon';

import { isRequired } from 'libs/validators';

// const TextFieldMemo = React.memo(TextField, (prev, next) => {
// return (
// prev.value === next.value &&
// prev.label === next.label &&
// prev.description === next.description
// );
// });

const StatefulTextbox = (props) => {
  let node = props.node;
  let fs = props.context;

  // const inputRef = React.useRef();
  // const inputRefs = window.inputRefs || [];
  // inputRefs[modelName] = inputRef;
  // window.inputRefs = inputRefs;

  let m = {};
  let mI = {};

  let dataField = props.dataField || node.dataField || node.name;
  let modelName = `data.${dataField}`;

  if (fs) {
    if (fs.model) {
      m = { ...fs.model(modelName) };
      mI = (fs.modelInfo ? fs.modelInfo[dataField] : {}) || {};
    }
  }

  let desc = node.description;
  if (m.error) {
    desc = m.message;
  }

  let password = mI.dataType === 'password' || node.password;
  let inputType = password ? 'password' : 'text';
  let disabled = false;
  if(fs) {
    disabled = fs._state['no-access'] || node.disabled || false;
  }

  const [state, setState] = React.useState({
    inputType: inputType,
    showPassword: false,
  });

  let variant = node.variant || 'outlined';
  let multiline = node.multiline || false;
  let fullWidth = node.fullWidth || false;
  let line = node.line || 1;

  if (multiline && node.line == undefined) {
    line = 4;
  }

  let cls = 'form-label';

  if (fullWidth) {
    cls += ' full-width';
  }

  let required = node.required || false;

  let defaultValue = node.defaultValue || '';

  if (defaultValue !== '') {
    fs.setStateWithWait({
      [`${modelName}`]: defaultValue,
    });
  }

  const handleClickShowPassword = () => {
    if (!state.showPassword) {
      setState({ inputType: 'text', showPassword: !state.showPassword });
      //inputRef.current.selectionStart = m.value.length;
      return;
    }
    setState({ inputType: inputType, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (required) {
      fs.useValidator({ [modelName]: isRequired });
    }
    return () => {
      if (required) {
        fs.useValidator({ [modelName]: false });
      }
    };
  }, []);

  return (
    <div
      style={{ flex: 1, padding: '4px' }}
      className={clsx(cls, node.className)}
    >
      {/*<pre>{JSON.stringify(props, null, 4)}</pre>*/}
      {variant !== 'standard' && node.label && (
        <Typography variant="h6">
          {required ? `${node.label} *` : node.label}
        </Typography>
      )}
      <TextField
        disabled={disabled}
        fullWidth
        margin="dense"
        //inputRef={inputRef}
        type={state.inputType}
        helperText={desc}
        {...m}
        variant={variant}
        placeholder={node.placeholder || node.label}
        multiline={multiline}
        rows={line}
        className="no-print"
        InputProps={
          inputType == 'password'
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {state.showPassword ? (
                        <Icon icon="muiVisibilityOff" />
                      ) : (
                        <Icon icon="muiVisibility" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : null
        }
      />
      <span style={{ fontSize: '14px' }} className="in-print">
        {m.value}
      </span>
    </div>
  );
};

RenderRegistry.add({
  inputText: StatefulTextbox,
});
