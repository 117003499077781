import React from 'react';
import path from 'path';
import { IconButton } from '@material-ui/core';
import Icon from 'components/icons/Icon';

import storage from 'libs/storage';
import config from 'config/config';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { format } from 'date-fns';
import { colors } from '@material-ui/core';
import { saveAs } from 'file-saver';

function fileListsAreEqual(prevProps, nextProps) {
  return (
    nextProps.value &&
    prevProps.value &&
    nextProps.value.length === prevProps.value.length
  );
}

const FileList = (props) => {
  let fs = props.context;
  let files = props.value || [];

  const erase = (idx) => {
    fs.setState({
      [`${props.model}.${idx}`]: {
        $splice: 1,
      },
    });
  };

  const download = (url, name) => {
    saveAs(url, name);
  };

  let filesRendered = files.map((f, idx) => {
    const backgroundImg = `url('${
      config.app.server.url + f.url
    }') no-repeat center center / cover`;
    return (
      <GridListTile key={f._id} style={{ padding: '.2em' }}>
        {/*<img src={config.app.server.url + f.url} alt={f.name} /> */}
        <div
          style={{ height: '100%', width: '100%', background: backgroundImg }}
          className="no-print"
        ></div>
        <img className="in-print" src={config.app.server.url + f.url}></img>
        <GridListTileBar
          title={f.name}
          subtitle={
            <span>{format(new Date(f.createdAt), 'MMM dd, yyyy')}</span>
          }
          actionIcon={
            <div style={{ display: 'flex' }}>
              {props.download && (
                <IconButton
                  onClick={() => {
                    download(`${config.app.server.url + f.url}`, f.name);
                  }}
                  aria-label="delete"
                  style={{ color: colors.grey[400] }}
                >
                  <Icon icon="muiSaveAlt" />
                </IconButton>
              )}
              {props.delete && (
                <IconButton
                  onClick={() => {
                    erase(idx);
                  }}
                  aria-label="delete"
                  style={{ color: colors.red[400] }}
                >
                  <Icon icon="muiDelete" />
                </IconButton>
              )}
            </div>
          }
        />
      </GridListTile>
    );
  });

  return (
    <GridList
      cols={files.length > 2 ? 2 : files.length}
      cellHeight={180}
      style={{ padding: '1em 0' }}
    >
      {filesRendered}
    </GridList>
  );
};

const FileListMemo = React.memo(FileList, fileListsAreEqual);

export default FileListMemo;
