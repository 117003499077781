import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';

const Header = (props) => {
  let node = props.node;
  let fs = props.context;

  let m = {};
  let mI = {};

  let dataField = props.dataField || node.dataField || node.name;
  let modelName = `data.${dataField}`;

  if (fs) {
    m = { ...fs.model(modelName) };
    mI = (fs.modelInfo ? fs.modelInfo[dataField] : {}) || {};
  }

  let h1 = `MuiTypography-h${node.size}`;

  let _props = { ...props };
  delete _props.staticContext;

  let text = node.text;

  if (m.value !== undefined && m.value !== '') {
    text = m.value;
  }

  return (
    <div className={node.className} {..._props}>
      <h1 className={h1}>{text}</h1>
    </div>
  );
};

RenderRegistry.add({
  header: Header,
});
