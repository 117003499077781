import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import RenderRegistry from './RenderRegistry';
import { findById } from 'libs/utility';

import './view';
import './menu';
import './elements';
import './form';
import './table';

import './code';

export default function App(props) {
  const app = useApp();

  let appId = app.state.activeApp;
  if (!appId) {
    return <div class="section">No app selected</div>;
  }

  let mainPage = {};
  let activeApp = findById(app.state, appId, { key: 'id' });
  if (activeApp && activeApp.startPage) {
    let view = findById(app.state, activeApp.startPage, { key: 'id' }) || {};
    mainPage = {
      ...view,
    };
    console.log(mainPage);
  }

  return (
    <React.Fragment>
      <div className="app-page">
        <RenderRegistry.Render node={mainPage} />
      </div>
    </React.Fragment>
  );
}
