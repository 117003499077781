import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import { useUI } from 'stores/UIStore';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  colors,
  Collapse,
  Chip,
} from '@material-ui/core';

const menuItemDelay = 350; // to allow animation to play

const MenuItem = withRouter((props) => {
  let node = props.node;
  let label = node.label || 'menuItem';

  let modelName = node.name || false;

  const ui = useUI();

  const onItemClick = (item) => {
    if (item.path) {
      setTimeout(() => {
        props.history.push(item.path);
      }, menuItemDelay);
    }
  };

  let counter = 0;

  if (ui.state.indicators && modelName) {
    counter = ui.state.indicators[modelName];
  }

  const [open, setOpen] = React.useState(false);

  const path =
    props.history.location.pathname.replace(/(?:[a-z\-])\/.+/g, '') || '';
  const menuItemPath = node.path || '';
  const children = node.children || [];

  React.useEffect(() => {
    setOpen(menuItemPath.startsWith(path));
  }, []);

  return (
    <React.Fragment>
      <ListItem
        // className={classes.item}
        disableGutters
        // key={page.title}
        button
        selected={menuItemPath.startsWith(path)}
        onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          onItemClick(node);
        }}
      >
        <ListItemText>{label}</ListItemText>
        {counter > 0 && <Chip size="small" label={counter} color="primary" />}
      </ListItem>
      {children.length > 0 && (
        <Collapse in={open}>
          {RenderRegistry.renderChildren(children, props)}
        </Collapse>
      )}
    </React.Fragment>
  );
});

const Menu = (props) => {
  const onItemClick = (item) => {
    console.log(item);
  };

  let node = props.node;

  return (
    <List {...props}>
      {RenderRegistry.renderChildren(node.children, props)}
    </List>
  );
};

RenderRegistry.add({
  menu: Menu,
  menuItem: MenuItem,
});
