import React from 'react';
import RenderRegistry from '../RenderRegistry';
import clsx from 'clsx';
import { useApp } from 'stores/AppStore';

const ActionBar = (props) => {
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;

  const app = useApp();

  const disabled = context._state['no-access'] || false;

  const getView = (name) => {
    const viewIndex = children.findIndex((x) => x.name === name);
    return (
      <React.Fragment>
        {viewIndex >= 0 ? (
          RenderRegistry.renderChildren([children[viewIndex]], {
            ...props,
            context: context,
          })
        ) : (
          <pre>not found</pre>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {!disabled && (
        <div className="actionbarButtons no-print">
          {getView('save')}
          {context._state.data._id != undefined && getView('delete')}
        </div>
      )}
    </React.Fragment>
  );
};

RenderRegistry.add({
  ActionBar,
});
