import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import RenderRegistry from 'apps/common/RenderRegistry';

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
  },
}));

export default (props) => {
  let node = props.node;

  const classes = useStyles();

  return (
    <div>
      <main className={classes.content}>
        {node ? <RenderRegistry.Render node={node} /> : props.children}
      </main>
    </div>
  );

  // return (
  //   <div
  //     style={{ display: 'flex', paddingLeft: sidebarWidth, paddingTop: '80px' }}
  //   >
  //     <Topbar />
  //     <Sidebar sidebarWidth={sidebarWidth} />
  //     <main style={{ flex: 1 }}>
  //       {node ? <RenderRegistry.Render node={node} /> : props.children}
  //     </main>
  //   </div>
  // );
};
