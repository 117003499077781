export default {
  auth: {
    server: {
      url: 'https://1670mabuhay.com',
    },
  },
  app: {
    id: 'fwd-dashboard',
    server: {
      url: 'https://1670mabuhay.com',
    },
    passwords: {
      questionnaire: "mabuhay2021",
      requirements: "mabuhay2021"
    },
    mobile: false,
  },
};
