import React from 'react';
import { Route, Redirect, withRouter, useLocation } from 'react-router-dom';
import { useAuth } from 'stores/AuthStore';
import { useApp } from 'stores/AppStore';
import { differenceInMinutes } from 'date-fns';

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  let node = props.node;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout node={node}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export const RouteWithAuthLayout = withRouter((props) => {
  const auth = useAuth();
  const app = useApp();
  const location = useLocation();
  let { component, layout } = props;
  console.log(props.requiresAuth);
  console.log(auth.state.user);

  if (props.requiresAuth && Object.keys(auth.state.user).length == 0) {
    console.log('No Access');
    return (
      <Redirect
        exact
        from={location.pathname}
        to={`/login`}
      />
    );
  }

  return <RouteWithLayout {...props} layout={layout} component={component} />;
});

export default RouteWithLayout;
