import React, { Fragment } from 'react';
// import { FontAwesomeIcon as TheFontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library as faLibrary } from './registry_fontawesome';
import { library as muiLibrary } from './registry_mui';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import debounce from 'debounce';

const iconsToGenerate = {};
const iconsGenerated = {};

// window.$ig = iconsGenerated;

const recreateIconSheet = debounce(() => {
  let content = '';
  Object.keys(iconsGenerated).forEach((k) => {
    let data = iconsGenerated[k];
    content += `${k} { background: url(data:image/svg+xml;base64,${data}) no-repeat; }`;
    content += '\n';
  });

  // console.log(iconsGenerated);
  console.log(content);

  let style = document.head.querySelector('#iconsheet');
  if (!style) {
    style = document.createElement('style');
    style.setAttribute('id', 'iconsheet');
    document.head.append(style);
  }

  style.innerText = content;
}, 1);

const generateIcon = async (selector, icon, props) => {
  let Icon = icon;
  let tmp = document.createElement('div');
  await ReactDOM.render(<Icon {...props} />, tmp);
  let svg = tmp.querySelector('svg');
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  iconsGenerated[selector] = window.btoa(svg.outerHTML);
  // console.log(selector);
};

const generateIcons = debounce(() => {
  let newIcons = [];
  Object.keys(iconsToGenerate).forEach((k) => {
    let g = iconsToGenerate[k];
    if (iconsGenerated[g]) {
      return;
    }

    generateIcon(g.selector, g.icon, g.props);
    newIcons.push(g);
  });

  if (newIcons.length) {
    recreateIconSheet();
  }
}, 1);

const AddIconCss = (className, icon, props) => {
  return new Promise((resolve) => {
    let selector = `.${className}`;

    iconsToGenerate[selector] = {
      selector: selector,
      icon: icon,
      props: { ...props },
    };

    generateIcons();

    resolve();
  });
};

function IconWrapper(props) {
  if (!props.icon) {
    console.warn('icon invalid');
    return <Fragment></Fragment>;
  }

  let icon = props.icon || {};

  // query registry
  if (typeof icon === 'string') {
    icon = Library[icon];
  }

  if (React.isValidElement(icon)) {
    return icon;
  }

  // catch mui
  if (
    typeof icon === 'function' ||
    (typeof icon === 'object' && icon.type && icon.type.render)
  ) {
    let Icon = icon;
    return <Icon {...props} />;
    // let className = `svgIcon_${icon.displayName}`;
    // AddIconCss(className, icon, props);
    // return <div {...props} className={clsx('svgIcon', className)}></div>;
  }

  // catch fontawesome
  if (!icon || !icon.prefix || icon.prefix.indexOf('fa') !== 0) {
    if (props.icon !== 'no-icon') {
      // console.warn('icon not found - ' + props.icon);
    }
    return <Fragment></Fragment>;
  }

  return <Fragment></Fragment>;
  // return <TheFontAwesomeIcon {...props} icon={icon} />;
}

export const FontAwesomeIcon = IconWrapper;

const IconWrapperMemo = React.memo(IconWrapper, (next, prev) => {
  if (typeof next.icon === 'string') {
    return next.icon === prev.icon;
  }
  return false;
});

export default IconWrapperMemo;

export const Library = {
  ...faLibrary,
  ...muiLibrary,
};

delete Library.add;
