import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import StateHelper from 'libs/stateHelper';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import events from 'libs/events';

const cx = new StateHelper();

const Searchbar = (props) => {
  const [state, setState] = React.useState({
    data: {
      search: '',
    },
  });

  let node = props.node;

  const app = useApp();
  const project = app.state;

  let subView;
  let _node;

  (project.children || []).forEach((c) => {
    if (c.type === 'view' && c.id === node.view) {
      subView = c;
      _node = subView;
    }
  });

  cx.useState(state, setState);

  React.useEffect(() => {
    if (state.data.search.trim().length > 0) {
      events.$emit('filter', state.data);
    } else {
      events.$emit('filter', {});
    }
  }, [state.data.search]);

  return (
    <div>
      {RenderRegistry.renderChildren(_node.children, { ...props, context: cx })}
    </div>
  );
};

RenderRegistry.add({
  Searchbar,
});
