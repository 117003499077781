import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';

const Image = (props) => {
  let node = props.node;

  let src = node.url;

  return (
    <div {...props}>
      <img src={src} />
    </div>
  );
};

RenderRegistry.add({
  image: Image,
});
