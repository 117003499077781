import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';

const TextBlock = (props) => {
  let node = props.node;
  let fs = props.context;

  let m = {};
  let mI = {};

  let dataField = props.dataField || node.dataField || node.name;
  let modelName = `data.${dataField}`;

  if (fs) {
    m = { ...fs.model(modelName) };
    mI = (fs.modelInfo ? fs.modelInfo[dataField] : {}) || {};
  }

  let text = node.text || '';

  if (dataField) {
    if (m.value !== '') {
      text = m.value;
    }
  }

  if (typeof text == 'object') {
    let obj = text;
    if (obj) {
      text =
        obj.name ||
        obj.description ||
        obj.number ||
        obj.label ||
        obj.firstName + ' ' + obj.lastName;
    }
  }

  let text_ = {};

  if (dataField) {
    text_ = fs.getState(`${modelName}_`) || {};
  }

  if (Object.keys(text_).length > 0) {
    let obj = text_;
    if (obj) {
      text =
        obj.name ||
        obj.description ||
        obj.number ||
        obj.label ||
        obj.firstName + ' ' + obj.lastName;
    }
  }

  let isCurrency = node.currency || false;

  if (isCurrency) {
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'PHP',
    });

    text = formatter.format(text);
  }

  return (
    <div className={node.className} {...props}>
      {text}
    </div>
  );
};

RenderRegistry.add({
  textBlock: TextBlock,
});
