import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import StateHelper from 'libs/stateHelper';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import events from 'libs/events';

import $http from 'libs/http';
import config from 'config/config';
import { useAuth } from 'stores/AuthStore';
import { useSnackbar } from 'notistack';
import { isRequired } from 'libs/validators';
import crud from 'libs/crud';

import UploadField from 'components/UploadField/UploadField';
import FileList from 'components/UploadField/FileList';

import path from 'path';
import $storage from 'libs/storage';
import { Button } from '@material-ui/core';
import Icon from 'components/icons/Icon';

const cx = new StateHelper();

const Account = withRouter((props) => {
  let node = props.node;

  const auth = useAuth();
  const [state, setState] = React.useState({
    data: {},
  });

  cx.useState(state, setState);

  React.useEffect(() => {
    cx.setState({
      data: {
        ...auth.state.user,
      },
    });
  }, [auth.state.user]);

  return (
    <div>
      {RenderRegistry.renderChildren(node.children, { ...props, context: cx })}
    </div>
  );
});

const AccountUnits = (props) => {
  const u = crud('units');
  const [units, setUnits] = React.useState([]);
  //fs.setState({ [units]: });

  const getUnits = async () => {
    const res = await u.find({});
    if (res) {
      if (res.data.length > 0) {
        const _units = res.data.map((el) => el.number);
        setUnits(_units);
      }
    }
  };

  React.useEffect(() => {
    getUnits();
  }, []);

  return (
    <div>
      {units.map((el, idx) => (
        <React.Fragment>
          <span key={idx}>{el}</span>
          {idx == units.length - 1 ? ' ' : ', '}
        </React.Fragment>
      ))}
    </div>
  );
};

const AccountPhoto = (props) => {
  // let fs = props.context;
  // let files = fs.getState(props.model) || [];

  let node = props.node;
  let base = node.basePath || '/';
  let fs = props.context;

  let m = {};
  let mI = {};

  let dataField = props.dataField || node.dataField || node.name;
  let modelName = `data.${dataField}`;

  if (fs) {
    m = { ...fs.model(modelName) };
    mI = (fs.modelInfo ? fs.modelInfo[node.dataField] : {}) || {};
  }

  let desc = node.description;
  if (m.error) {
    desc = m.message;
  }

  let label = node.label;

  let value = m.value;

  if (typeof value === 'object' && value.length === undefined) {
    value = [value];
  }

  const onUploadSuccess = (files) => {
    if (m.onChange) {
      setTimeout(() => {
        m.onChange({
          target: {
            name: modelName || props.node.name,
            value: [...files],
          },
        });
      }, 50);
    }
  };

  const upload = (files) => {
    let p = [];
    Array.prototype.forEach.call(files || [], (file) => {
      p.push(
        $storage.upload(file, {
          path: path.join(base || '', file.name),
          // path: 'tmp/' + file.name,
          onUploadProgress: (p) => {
            console.log(file.name);
            console.log(p);
          },
        })
      );
    });

    Promise.all(p).then((res) => {
      let files = res.map((f) => {
        if (!f || !f.data) {
          return '';
        }
        return f.data.file;
      });

      onUploadSuccess(files);
    });
  };

  const onFileChange = (evt) => {
    upload(evt.target.files);
  };

  return (
    <div className="form-label" style={{ flex: 1, margin: '4px' }}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={`${modelName}-contained-button-file`}
        type="file"
        onChange={onFileChange}
      />

      {m.value !== '' && m.value.length > 0 ? (
        <label htmlFor={`${modelName}-contained-button-file`}>
          <div className="profile-photo-container">
            <div
              style={{
                width: '100%',
                height: '100%',
                background: `url(${config.app.server.url}/storage/${m.value[0]._id}) no-repeat center center / cover`,
              }}
            ></div>
            <div class="profile-photo-overlay">
              <Icon className="mr-1" icon="muiEdit" /> Change Photo
            </div>
          </div>
        </label>
      ) : (
        <label htmlFor={`${modelName}-contained-button-file`}>
          <div className="profile-photo-container">
            <div
              style={{
                width: '100%',
                height: '100%',
                background: `url(/assets/images/placeholder-profile-pic.png) no-repeat center center / cover`,
              }}
            ></div>
            <div class="profile-photo-overlay">
              <Icon className="mr-1" icon="muiEdit" /> Change Photo
            </div>
          </div>
        </label>
      )}
    </div>
  );
};

const AccountStaff = withRouter((props) => {
  let node = props.node;

  const auth = useAuth();
  const [state, setState] = React.useState({
    data: {},
  });

  const s = crud('employees/me');

  cx.useState(state, setState);

  const getEmployees = async () => {
    const res = await s.find({});
    if (res) {
      if (res.data) {
        cx.setState({ data: res.data });
      }
    }
  };

  React.useEffect(() => {
    getEmployees();
  }, []);

  // React.useEffect(() => {
  //   cx.setState({
  //     data: {
  //       ...auth.state.user,
  //     },
  //   });
  // }, [auth.state.user]);

  return (
    <div>
      {RenderRegistry.renderChildren(node.children, { ...props, context: cx })}
    </div>
  );
});

RenderRegistry.add({
  Account,
  AccountUnits,
  AccountPhoto,
  AccountStaff,
});
