const app = {
    "id": "60b4ffaf865b9a32f8b17d6e",
    "type": "project",
    "children": [
        {
            "id": "84373f41-c21f-11eb-bc09-3fdfcb6a7f06",
            "type": "view",
            "children": [
                {
                    "name": "menu",
                    "type": "menu",
                    "children": [
                        {
                            "id": "484971a0-c27f-11eb-84f3-e36d2708d8c5",
                            "type": "menuItem",
                            "children": [],
                            "label": "Home",
                            "path": "/home"
                        },
                        {
                            "id": "66de8c90-cfea-11eb-891c-1b5caf038a78",
                            "type": "menuItem",
                            "children": [],
                            "path": "/applicants-list",
                            "label": "Applicants",
                            "access": "admin,operations"
                        },
                        {
                            "id": "586cbbb0-d2fa-11eb-8348-dd0bd767fc37",
                            "type": "menuItem",
                            "children": [],
                            "path": "/agents-list",
                            "label": "Agents",
                            "access": "admin,operations"
                        },
                        {
                            "id": "57b15580-ff9d-11eb-ba64-6b8ec701b70f",
                            "type": "menuItem",
                            "children": [],
                            "path": "/banners-list",
                            "label": "Banners",
                            "access": "admin,marketing"
                        },
                        {
                            "id": "f9c68a60-c22c-11eb-a006-09ab5f845271",
                            "type": "menuItem",
                            "children": [],
                            "path": "/articles-list",
                            "label": "Blogs",
                            "name": "",
                            "access": "admin,marketing"
                        },
                        {
                            "id": "c44fcef0-c27c-11eb-84f3-e36d2708d8c5",
                            "type": "menuItem",
                            "children": [],
                            "path": "/teams-list",
                            "label": "About Us",
                            "access": "admin,marketing"
                        },
                        {
                            "id": "d7e3ae70-c27f-11eb-84f3-e36d2708d8c5",
                            "type": "menuItem",
                            "children": [],
                            "path": "/products-list",
                            "label": "Products",
                            "access": "admin,marketing"
                        },
                        {
                            "id": "38000560-c280-11eb-84f3-e36d2708d8c5",
                            "type": "menuItem",
                            "children": [],
                            "path": "/videos-list",
                            "label": "Media",
                            "access": "admin,marketing"
                        },
                        {
                            "id": "f1e08710-e919-11eb-8322-61ae0e006741",
                            "type": "menuItem",
                            "children": [],
                            "path": "/trainings-list",
                            "label": "Trainings",
                            "access": "admin,marketing"
                        },
                        {
                            "id": "93e31740-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "menuItem",
                            "children": [],
                            "path": "/webinars-list",
                            "label": "Lets Talk",
                            "access": "admin,marketing"
                        },
                        {
                            "id": "45b319e0-c280-11eb-84f3-e36d2708d8c5",
                            "type": "menuItem",
                            "children": [],
                            "label": "Questionnaire",
                            "path": "/questionnaire",
                            "access": "admin,operations"
                        },
                        {
                            "id": "7bcb9da0-d968-11eb-8bce-d32a5a25fbc1",
                            "type": "menuItem",
                            "children": [],
                            "path": "/requirements",
                            "label": "Requirements",
                            "access": "admin,operations"
                        },
                        {
                            "id": "338367f0-58b8-11ed-afdf-3d9d7389bdf5",
                            "type": "menuItem",
                            "children": [],
                            "name": "",
                            "access": "admin,operations",
                            "path": "/onboarding",
                            "label": "Onboarding"
                        },
                        {
                            "id": "85055fd0-ef31-11eb-a551-7389f799209f",
                            "type": "menuItem",
                            "children": [],
                            "path": "/downloadables-list",
                            "label": "Downloadables",
                            "access": "admin,operations"
                        },
                        {
                            "id": "b12551a0-04fe-11ec-8d04-3d15fb0558ec",
                            "type": "menuItem",
                            "children": [],
                            "path": "/testimonials-list",
                            "label": "Testimonials",
                            "access": "admin,marketing"
                        },
                        {
                            "id": "1f0571b0-103e-11ec-8abd-bbbf6c0700e6",
                            "type": "menuItem",
                            "children": [],
                            "path": "/members-list",
                            "label": "Admins",
                            "access": "admin"
                        },
                        {
                            "id": "4cdf5800-c280-11eb-84f3-e36d2708d8c5",
                            "type": "menuItem",
                            "children": [],
                            "command": "",
                            "label": "Logout",
                            "path": "/login"
                        }
                    ],
                    "id": "82b86771-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "dashboard-sidebar",
            "_sortIndex": 0,
            "className": "dashboard-sidebar"
        },
        {
            "id": "84376650-c21f-11eb-bc09-3fdfcb6a7f06",
            "type": "view",
            "children": [
                {
                    "id": "8dbb47b0-c22d-11eb-b1c1-41a3af73fe6e",
                    "type": "container",
                    "children": [
                        {
                            "name": "new",
                            "label": "New",
                            "command": "new",
                            "type": "button",
                            "className": "form-label",
                            "id": "82b88e82-c21f-11eb-bc09-3fdfcb6a7f06",
                            "startIcon": "AddCircle"
                        },
                        {
                            "id": "82b88e81-c21f-11eb-bc09-3fdfcb6a7f06",
                            "type": "inputText",
                            "children": [],
                            "name": "search",
                            "className": "",
                            "placeholder": "Search"
                        }
                    ],
                    "orientation": "horizontal"
                }
            ],
            "orientation": "horizontal",
            "component": "Searchbar",
            "name": "searchbar",
            "_sortIndex": 10
        },
        {
            "id": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06",
            "type": "view",
            "children": [
                {
                    "id": "8501e470-c22e-11eb-9949-239fc6bc80cc",
                    "type": "container",
                    "children": [
                        {
                            "name": "save",
                            "type": "button",
                            "id": "82b8b591-c21f-11eb-bc09-3fdfcb6a7f06",
                            "startIcon": "Save",
                            "command": "save"
                        },
                        {
                            "name": "delete",
                            "type": "button",
                            "id": "82b8b592-c21f-11eb-bc09-3fdfcb6a7f06",
                            "startIcon": "Delete",
                            "command": "delete"
                        }
                    ],
                    "orientation": "horizontal",
                    "component": "ActionBar"
                }
            ],
            "orientation": "horizontal",
            "name": "commandbar",
            "_sortIndex": 20
        },
        {
            "id": "99bf06e0-c21f-11eb-bc09-3fdfcb6a7f06",
            "type": "app",
            "children": [],
            "name": "dashboard",
            "sidebar": "84373f41-c21f-11eb-bc09-3fdfcb6a7f06",
            "startPage": "",
            "_sortIndex": 30,
            "requireAuth": true
        },
        {
            "id": "c15fae20-c21f-11eb-bc09-3fdfcb6a7f06",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c15fae21-c21f-11eb-bc09-3fdfcb6a7f06",
                    "children": [
                        {
                            "id": "c44f5400-c21f-11eb-bc09-3fdfcb6a7f06",
                            "type": "header",
                            "children": [],
                            "size": 2,
                            "text": "Welcome"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "route": "/home",
            "name": "welcome-page",
            "_sortIndex": 40,
            "requireAuth": true
        },
        {
            "id": "dcb58e00-c220-11eb-88be-1f6a1a2c9837",
            "type": "model",
            "children": [
                {
                    "id": "eda44580-c220-11eb-88be-1f6a1a2c9837",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "title",
                    "_sortIndex": 0
                },
                {
                    "id": "f6155fb0-c220-11eb-88be-1f6a1a2c9837",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "author",
                    "_sortIndex": 10
                },
                {
                    "id": "fc6101d0-c220-11eb-88be-1f6a1a2c9837",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "mm/dd/yyyy",
                    "name": "date",
                    "_sortIndex": 19
                },
                {
                    "id": "13d64190-c221-11eb-88be-1f6a1a2c9837",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "body",
                    "_sortIndex": 30
                },
                {
                    "id": "1af18fc0-c221-11eb-88be-1f6a1a2c9837",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "tags",
                    "_sortIndex": 40
                },
                {
                    "id": "a8531cd0-c230-11eb-861c-0b183fd9432f",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "photos"
                }
            ],
            "name": "article",
            "_sortIndex": 50
        },
        {
            "id": "3e02ee10-c225-11eb-8898-6125b02255f7",
            "type": "page",
            "children": [
                {
                    "id": "99fb0f60-c228-11eb-883e-d5e66268b358",
                    "type": "subView",
                    "children": [],
                    "view": "cbdc5620-c227-11eb-883e-d5e66268b358"
                }
            ],
            "orientation": "horizontal",
            "name": "login-page",
            "route": "/login",
            "layout": "full",
            "className": "login-container",
            "_sortIndex": 60
        },
        {
            "id": "cbdc5620-c227-11eb-883e-d5e66268b358",
            "type": "view",
            "children": [
                {
                    "id": "fd5bd860-c227-11eb-883e-d5e66268b358",
                    "type": "container",
                    "children": [
                        {
                            "id": "0ed2f9c0-c228-11eb-883e-d5e66268b358",
                            "type": "container",
                            "children": [
                                {
                                    "id": "125801d0-c228-11eb-883e-d5e66268b358",
                                    "type": "image",
                                    "children": [],
                                    "url": "./assets/images/logo.png"
                                }
                            ],
                            "orientation": "horizontal",
                            "className": "login-comp-logo"
                        },
                        {
                            "id": "24c107d0-c229-11eb-883e-d5e66268b358",
                            "type": "container",
                            "children": [
                                {
                                    "id": "256d7470-c229-11eb-883e-d5e66268b358",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "56064030-c229-11eb-883e-d5e66268b358",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "5f9dd900-c229-11eb-883e-d5e66268b358",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "label": "",
                                                    "name": "email",
                                                    "placeholder": "Email",
                                                    "className": "form-control login-form-input",
                                                    "variant": ""
                                                },
                                                {
                                                    "id": "7cd6ed40-c229-11eb-883e-d5e66268b358",
                                                    "type": "image",
                                                    "children": [],
                                                    "className": "login-icons",
                                                    "url": "./assets/images/icon_user.svg"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "col"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "row mt-4"
                                },
                                {
                                    "id": "30279ed0-c22a-11eb-989e-2fd903e0633a",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "37407c00-c22a-11eb-989e-2fd903e0633a",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "3a0197d0-c22a-11eb-989e-2fd903e0633a",
                                                    "type": "inputText",
                                                    "children": [],
                                                    "password": true,
                                                    "placeholder": "Password",
                                                    "name": "password",
                                                    "className": "form-control login-form-input"
                                                },
                                                {
                                                    "id": "49098df0-c22a-11eb-989e-2fd903e0633a",
                                                    "type": "image",
                                                    "children": [],
                                                    "url": "./assets/images/icon_login_password.svg",
                                                    "className": "login-icons"
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "col"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "row mt-2"
                                },
                                {
                                    "id": "907517e0-c22a-11eb-989e-2fd903e0633a",
                                    "type": "container",
                                    "children": [
                                        {
                                            "id": "978801a0-c22a-11eb-989e-2fd903e0633a",
                                            "type": "container",
                                            "children": [
                                                {
                                                    "id": "9b418730-c22a-11eb-989e-2fd903e0633a",
                                                    "type": "button",
                                                    "children": [],
                                                    "command": "local_login",
                                                    "text": "Login",
                                                    "fullWidth": true,
                                                    "variant": ""
                                                }
                                            ],
                                            "orientation": "horizontal",
                                            "className": "col"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "className": "row mt-3"
                                }
                            ],
                            "orientation": "vertical"
                        }
                    ],
                    "orientation": "vertical",
                    "component": "Login",
                    "className": "login-form-container"
                }
            ],
            "orientation": "vertical",
            "name": "login",
            "_sortIndex": 70
        },
        {
            "id": "c2fea860-c230-11eb-861c-0b183fd9432f",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c2fea861-c230-11eb-861c-0b183fd9432f",
                    "children": [
                        {
                            "id": "c303d880-c230-11eb-861c-0b183fd9432f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c303ff91-c230-11eb-861c-0b183fd9432f",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c303ff92-c230-11eb-861c-0b183fd9432f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c303ff94-c230-11eb-861c-0b183fd9432f",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "author",
                                    "label": "Author",
                                    "dataField": "author",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c303ff95-c230-11eb-861c-0b183fd9432f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c303ff97-c230-11eb-861c-0b183fd9432f",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "date",
                                    "label": "Date",
                                    "dataField": "date",
                                    "format": "MM/dd/yyyy"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c303ff98-c230-11eb-861c-0b183fd9432f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c303ff9a-c230-11eb-861c-0b183fd9432f",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "body",
                                    "label": "Body",
                                    "dataField": "body",
                                    "multiline": true,
                                    "line": "40",
                                    "required": true,
                                    "className": "fullwidth-input"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c303ff9b-c230-11eb-861c-0b183fd9432f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c303ff9d-c230-11eb-861c-0b183fd9432f",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "tags",
                                    "label": "Tags (separated by comma)",
                                    "dataField": "tags"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c303ff9e-c230-11eb-861c-0b183fd9432f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c303ffa0-c230-11eb-861c-0b183fd9432f",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "photos",
                                    "label": "Photos",
                                    "dataField": "photos",
                                    "basePath": "articles",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "c30203c0-c230-11eb-861c-0b183fd9432f",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "article-page",
            "route": "/articles/:id",
            "dataModel": "dcb58e00-c220-11eb-88be-1f6a1a2c9837",
            "access": "noone",
            "requireAuth": true,
            "_sortIndex": 80
        },
        {
            "id": "c305fb60-c230-11eb-861c-0b183fd9432f",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c2fea861-c230-11eb-861c-0b183fd9432f",
                    "children": [
                        {
                            "id": "c3081e40-c230-11eb-861c-0b183fd9432f",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "c30a4120-c230-11eb-861c-0b183fd9432f",
                            "type": "table",
                            "children": [
                                {
                                    "id": "c30c15e0-c230-11eb-861c-0b183fd9432f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                },
                                {
                                    "id": "c30c15e2-c230-11eb-861c-0b183fd9432f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "author",
                                    "label": "Author",
                                    "dataField": "author"
                                },
                                {
                                    "id": "c30c15e4-c230-11eb-861c-0b183fd9432f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "date",
                                    "label": "Date",
                                    "dataField": "date",
                                    "date": true
                                },
                                {
                                    "id": "c30c15e6-c230-11eb-861c-0b183fd9432f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "body",
                                    "label": "Body",
                                    "dataField": "body"
                                },
                                {
                                    "id": "c30c15e8-c230-11eb-861c-0b183fd9432f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "tags",
                                    "label": "Tags",
                                    "dataField": "tags"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "article-list",
            "route": "/articles-list",
            "dataModel": "dcb58e00-c220-11eb-88be-1f6a1a2c9837",
            "requireAuth": true,
            "_sortIndex": 90
        },
        {
            "id": "94a003a0-c27c-11eb-84f3-e36d2708d8c5",
            "type": "model",
            "children": [
                {
                    "id": "a0a60460-c27c-11eb-84f3-e36d2708d8c5",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "name"
                },
                {
                    "id": "acd69790-c27c-11eb-84f3-e36d2708d8c5",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "text"
                },
                {
                    "id": "b590c8b0-c27c-11eb-84f3-e36d2708d8c5",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "photo"
                }
            ],
            "name": "team",
            "_sortIndex": 100
        },
        {
            "id": "c4476a80-c27c-11eb-84f3-e36d2708d8c5",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c4476a81-c27c-11eb-84f3-e36d2708d8c5",
                    "children": [
                        {
                            "id": "c449db80-c27c-11eb-84f3-e36d2708d8c5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c449db82-c27c-11eb-84f3-e36d2708d8c5",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "name",
                                    "label": "Name",
                                    "dataField": "name",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c449db83-c27c-11eb-84f3-e36d2708d8c5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c449db85-c27c-11eb-84f3-e36d2708d8c5",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "text",
                                    "label": "Text",
                                    "dataField": "text",
                                    "multiline": true,
                                    "line": "12",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "c449db86-c27c-11eb-84f3-e36d2708d8c5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "c449db88-c27c-11eb-84f3-e36d2708d8c5",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "photo",
                                    "label": "Photo",
                                    "dataField": "photo",
                                    "basePath": "teams",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "c448ca10-c27c-11eb-84f3-e36d2708d8c5",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "team-page",
            "route": "/teams/:id",
            "dataModel": "94a003a0-c27c-11eb-84f3-e36d2708d8c5",
            "access": "noone",
            "requireAuth": true,
            "_sortIndex": 110
        },
        {
            "id": "c44b3b10-c27c-11eb-84f3-e36d2708d8c5",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c4476a81-c27c-11eb-84f3-e36d2708d8c5",
                    "children": [
                        {
                            "id": "c44c7390-c27c-11eb-84f3-e36d2708d8c5",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "c44d8500-c27c-11eb-84f3-e36d2708d8c5",
                            "type": "table",
                            "children": [
                                {
                                    "id": "c44ee490-c27c-11eb-84f3-e36d2708d8c5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "name",
                                    "label": "Name",
                                    "dataField": "name"
                                },
                                {
                                    "id": "c44ee492-c27c-11eb-84f3-e36d2708d8c5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "text",
                                    "label": "Text",
                                    "dataField": "text"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "team-list",
            "route": "/teams-list",
            "dataModel": "94a003a0-c27c-11eb-84f3-e36d2708d8c5",
            "requireAuth": true,
            "_sortIndex": 120
        },
        {
            "id": "9aa21240-c27f-11eb-84f3-e36d2708d8c5",
            "type": "model",
            "children": [
                {
                    "id": "9ed5a9d0-c27f-11eb-84f3-e36d2708d8c5",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "title"
                },
                {
                    "id": "a29040d0-c27f-11eb-84f3-e36d2708d8c5",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "description"
                },
                {
                    "id": "c16fb210-c27f-11eb-84f3-e36d2708d8c5",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "photo"
                },
                {
                    "id": "cf20a3b0-c27f-11eb-84f3-e36d2708d8c5",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "hyperlink"
                }
            ],
            "name": "product",
            "_sortIndex": 130
        },
        {
            "id": "d7dc0d50-c27f-11eb-84f3-e36d2708d8c5",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c4476a81-c27c-11eb-84f3-e36d2708d8c5",
                    "children": [
                        {
                            "id": "d7de7e50-c27f-11eb-84f3-e36d2708d8c5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d7de7e52-c27f-11eb-84f3-e36d2708d8c5",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "d7dea560-c27f-11eb-84f3-e36d2708d8c5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d7dea562-c27f-11eb-84f3-e36d2708d8c5",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description",
                                    "multiline": true,
                                    "line": "12",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "d7dea563-c27f-11eb-84f3-e36d2708d8c5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d7dea565-c27f-11eb-84f3-e36d2708d8c5",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "photo",
                                    "label": "Photo",
                                    "dataField": "photo",
                                    "basePath": "products",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "d7dea566-c27f-11eb-84f3-e36d2708d8c5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "d7dea568-c27f-11eb-84f3-e36d2708d8c5",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "hyperlink",
                                    "label": "Hyperlink",
                                    "dataField": "hyperlink",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "d7dd93f0-c27f-11eb-84f3-e36d2708d8c5",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "product-page",
            "route": "/products/:id",
            "dataModel": "9aa21240-c27f-11eb-84f3-e36d2708d8c5",
            "access": "noone",
            "requireAuth": true,
            "_sortIndex": 140
        },
        {
            "id": "d7df8fc0-c27f-11eb-84f3-e36d2708d8c5",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "c4476a81-c27c-11eb-84f3-e36d2708d8c5",
                    "children": [
                        {
                            "id": "d7e07a20-c27f-11eb-84f3-e36d2708d8c5",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "d7e13d70-c27f-11eb-84f3-e36d2708d8c5",
                            "type": "table",
                            "children": [
                                {
                                    "id": "d7e2c410-c27f-11eb-84f3-e36d2708d8c5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                },
                                {
                                    "id": "d7e2c412-c27f-11eb-84f3-e36d2708d8c5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description"
                                },
                                {
                                    "id": "d7e2c416-c27f-11eb-84f3-e36d2708d8c5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "hyperlink",
                                    "label": "Hyperlink",
                                    "dataField": "hyperlink"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "product-list",
            "route": "/products-list",
            "dataModel": "9aa21240-c27f-11eb-84f3-e36d2708d8c5",
            "requireAuth": true,
            "_sortIndex": 150
        },
        {
            "id": "1ed1cb00-c280-11eb-84f3-e36d2708d8c5",
            "type": "model",
            "children": [
                {
                    "id": "2daae8f0-c280-11eb-84f3-e36d2708d8c5",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "title"
                },
                {
                    "id": "30186680-c280-11eb-84f3-e36d2708d8c5",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "hyperlink"
                },
                {
                    "id": "408183d0-ce74-11eb-89ea-ad5f3dfb7edb",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "description"
                },
                {
                    "id": "2c3b7f30-0c26-11ec-9935-5d7aa1e4a4c6",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "tags"
                }
            ],
            "name": "video",
            "_sortIndex": 160
        },
        {
            "id": "6c844cb0-ce74-11eb-8410-27bff763e8a6",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "6c8473c0-ce74-11eb-8410-27bff763e8a6",
                    "children": [
                        {
                            "id": "6c8a4020-ce74-11eb-8410-27bff763e8a6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6c8a4022-ce74-11eb-8410-27bff763e8a6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "6c8a4023-ce74-11eb-8410-27bff763e8a6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6c8a4025-ce74-11eb-8410-27bff763e8a6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "hyperlink",
                                    "label": "Hyperlink",
                                    "dataField": "hyperlink",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "6c8a4026-ce74-11eb-8410-27bff763e8a6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "6c8a4028-ce74-11eb-8410-27bff763e8a6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description",
                                    "line": "12",
                                    "multiline": true,
                                    "required": false
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "4c4c4480-0c26-11ec-9935-5d7aa1e4a4c6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "4e0c4400-0c26-11ec-9935-5d7aa1e4a4c6",
                                    "type": "inputText",
                                    "children": [],
                                    "dataField": "tags",
                                    "label": "Tags (separated by comma)"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "6c88b980-ce74-11eb-8410-27bff763e8a6",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "video-page",
            "route": "/videos/:id",
            "dataModel": "1ed1cb00-c280-11eb-84f3-e36d2708d8c5",
            "access": "noone",
            "_sortIndex": 170
        },
        {
            "id": "6c8b9fb0-ce74-11eb-8410-27bff763e8a6",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "6c8473c0-ce74-11eb-8410-27bff763e8a6",
                    "children": [
                        {
                            "id": "6c8d7470-ce74-11eb-8410-27bff763e8a6",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "6c8f9750-ce74-11eb-8410-27bff763e8a6",
                            "type": "table",
                            "children": [
                                {
                                    "id": "6c91ba30-ce74-11eb-8410-27bff763e8a6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                },
                                {
                                    "id": "6c91ba32-ce74-11eb-8410-27bff763e8a6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "hyperlink",
                                    "label": "Hyperlink",
                                    "dataField": "hyperlink"
                                },
                                {
                                    "id": "6c91ba34-ce74-11eb-8410-27bff763e8a6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "video-list",
            "route": "/videos-list",
            "dataModel": "1ed1cb00-c280-11eb-84f3-e36d2708d8c5",
            "_sortIndex": 180
        },
        {
            "id": "61d6a850-cfda-11eb-891c-1b5caf038a78",
            "type": "model",
            "children": [
                {
                    "id": "6a42b970-cfda-11eb-891c-1b5caf038a78",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "firstname"
                },
                {
                    "id": "aa769090-cffb-11eb-badf-011714d80ecd",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "lastname"
                },
                {
                    "id": "6ed95890-cfda-11eb-891c-1b5caf038a78",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "email"
                },
                {
                    "id": "78b35190-cfda-11eb-891c-1b5caf038a78",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "contact"
                },
                {
                    "id": "8d704f20-cfda-11eb-891c-1b5caf038a78",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "address"
                },
                {
                    "id": "c577ed00-cfea-11eb-891c-1b5caf038a78",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "MM/dd/yyyy",
                    "name": "birthday"
                },
                {
                    "id": "e24ed480-d291-11eb-98e5-637d2974a413",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "prefix"
                },
                {
                    "id": "e5f54740-d291-11eb-98e5-637d2974a413",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "alternateContact"
                },
                {
                    "id": "155089f0-d292-11eb-98e5-637d2974a413",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "middlename"
                },
                {
                    "id": "b7946120-d2f4-11eb-88e1-d5d6058d6afc",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "status"
                },
                {
                    "id": "bc9003a0-d2f4-11eb-88e1-d5d6058d6afc",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "status_"
                },
                {
                    "id": "cf532d00-d2f4-11eb-88e1-d5d6058d6afc",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "leader"
                },
                {
                    "id": "d353a6a0-d2f4-11eb-88e1-d5d6058d6afc",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "leader_"
                },
                {
                    "id": "3b44e480-d305-11eb-912f-e3b949e640a5",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "MM/dd/yyyy",
                    "name": "dateStarted"
                },
                {
                    "id": "c33b7c60-da70-11eb-a2dd-15663b87a3c1",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "agentNumber"
                },
                {
                    "id": "f1d19220-ee2b-11eb-af14-a1e3a699d0de",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "referredBy"
                },
                {
                    "id": "fa4432f0-ee2b-11eb-af14-a1e3a699d0de",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "referredBy_"
                },
                {
                    "id": "20b608f0-367f-11ec-ba12-a7126a3a1978",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "position"
                },
                {
                    "id": "284379e0-367f-11ec-ba12-a7126a3a1978",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "position_"
                }
            ],
            "name": "applicant",
            "_sortIndex": 189
        },
        {
            "id": "540458c0-cfea-11eb-891c-1b5caf038a78",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "540458c1-cfea-11eb-891c-1b5caf038a78",
                    "children": [
                        {
                            "id": "540b3690-cfea-11eb-891c-1b5caf038a78",
                            "type": "container",
                            "children": [
                                {
                                    "id": "540b3692-cfea-11eb-891c-1b5caf038a78",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "name",
                                    "label": "Name",
                                    "dataField": "name"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "540b3693-cfea-11eb-891c-1b5caf038a78",
                            "type": "container",
                            "children": [
                                {
                                    "id": "540b3695-cfea-11eb-891c-1b5caf038a78",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "email",
                                    "label": "Email",
                                    "dataField": "email"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "540b3696-cfea-11eb-891c-1b5caf038a78",
                            "type": "container",
                            "children": [
                                {
                                    "id": "540b3698-cfea-11eb-891c-1b5caf038a78",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "contact",
                                    "label": "Contact",
                                    "dataField": "contact"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "540b3699-cfea-11eb-891c-1b5caf038a78",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e0fb6200-cfea-11eb-891c-1b5caf038a78",
                                    "type": "inputDate",
                                    "children": [],
                                    "format": "MM/dd/yyyy",
                                    "name": "birthday",
                                    "label": "Birthday",
                                    "dataField": "birthday"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "540b369c-cfea-11eb-891c-1b5caf038a78",
                            "type": "container",
                            "children": [
                                {
                                    "id": "540b369e-cfea-11eb-891c-1b5caf038a78",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "address",
                                    "label": "Address",
                                    "dataField": "address",
                                    "multiline": true,
                                    "line": "8"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "b33548d0-cfeb-11eb-9935-fd22f3cfa7ae",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b5909df0-cfeb-11eb-9935-fd22f3cfa7ae",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Leader"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "5408eca0-cfea-11eb-891c-1b5caf038a78",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "client-page",
            "route": "/clients/:id",
            "dataModel": "61d6a850-cfda-11eb-891c-1b5caf038a78",
            "access": "noone"
        },
        {
            "id": "e4278320-d2f4-11eb-88e1-d5d6058d6afc",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "e4278321-d2f4-11eb-88e1-d5d6058d6afc",
                    "children": [
                        {
                            "id": "e42c6527-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e42c6529-d2f4-11eb-88e1-d5d6058d6afc",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "prefix",
                                    "label": "Prefix",
                                    "dataField": "prefix"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e42c3e10-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e42c3e12-d2f4-11eb-88e1-d5d6058d6afc",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "firstname",
                                    "label": "Firstname",
                                    "dataField": "firstname"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e42c652d-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e42c652f-d2f4-11eb-88e1-d5d6058d6afc",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "middlename",
                                    "label": "Middlename",
                                    "dataField": "middlename"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e42c3e13-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e42c3e15-d2f4-11eb-88e1-d5d6058d6afc",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "lastname",
                                    "label": "Lastname",
                                    "dataField": "lastname"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e42c3e16-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e42c3e18-d2f4-11eb-88e1-d5d6058d6afc",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "email",
                                    "label": "Email",
                                    "dataField": "email"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e42c3e19-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e42c6520-d2f4-11eb-88e1-d5d6058d6afc",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "contact",
                                    "label": "Contact",
                                    "dataField": "contact"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e42c6521-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e42c6523-d2f4-11eb-88e1-d5d6058d6afc",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "address",
                                    "label": "Address",
                                    "dataField": "address",
                                    "multiline": true,
                                    "line": "8"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e42c6524-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e42c6526-d2f4-11eb-88e1-d5d6058d6afc",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "birthday",
                                    "label": "Birthday",
                                    "dataField": "birthday",
                                    "format": "MM/dd/yyyy"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e42c652a-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e42c652c-d2f4-11eb-88e1-d5d6058d6afc",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "alternateContact",
                                    "label": "AlternateContact",
                                    "dataField": "alternateContact"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e42c6530-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "809a04d0-d2f5-11eb-88e1-d5d6058d6afc",
                                    "type": "inputSelect",
                                    "children": [],
                                    "label": "Status",
                                    "dataField": "status",
                                    "saveObject": true,
                                    "options": "[{\"label\": \"Applicant\", \"value\": \"applicant\"}, {\"label\": \"In-Process\", \"value\": \"in-process\"}, {\"label\": \"Agent\", \"value\": \"agent\"}]"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "e42c6533-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e42c6535-d2f4-11eb-88e1-d5d6058d6afc",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "leader",
                                    "label": "Leader",
                                    "dataField": "leader",
                                    "component": "LeaderPicker"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "2ed95510-d30e-11eb-b33a-e17386e014f4",
                            "type": "container",
                            "children": [
                                {
                                    "id": "30a71030-d30e-11eb-b33a-e17386e014f4",
                                    "type": "inputDate",
                                    "children": [],
                                    "dataField": "dateStarted",
                                    "label": "Date Started",
                                    "format": "MM/dd/yyyy"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "2c170550-ee2c-11eb-af14-a1e3a699d0de",
                            "type": "container",
                            "children": [
                                {
                                    "id": "443ef340-ee2c-11eb-af14-a1e3a699d0de",
                                    "type": "inputText",
                                    "children": [],
                                    "label": "Referred By",
                                    "dataField": "referredBy",
                                    "disabled": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "f46102f0-3200-11ec-b71d-8d340ebaf626",
                            "type": "container",
                            "children": [
                                {
                                    "id": "fe858d60-367d-11ec-88f0-55e198b54c7e",
                                    "type": "inputSelect",
                                    "children": [],
                                    "component": "PositionPicker",
                                    "label": "Position"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ],
                    "component": ""
                },
                {
                    "id": "e42a9060-d2f4-11eb-88e1-d5d6058d6afc",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "applicant-page",
            "route": "/applicants/:id",
            "dataModel": "61d6a850-cfda-11eb-891c-1b5caf038a78",
            "access": ""
        },
        {
            "id": "e42e12d0-d2f4-11eb-88e1-d5d6058d6afc",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "e4278321-d2f4-11eb-88e1-d5d6058d6afb",
                    "children": [
                        {
                            "id": "e42f9970-d2f4-11eb-88e1-d5d6058d6afc",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "114f2cc0-e337-11eb-866f-d51fc165ecb2",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e4314720-d2f4-11eb-88e1-d5d6058d6afc",
                                    "type": "table",
                                    "children": [
                                        {
                                            "id": "e432cdcc-d2f4-11eb-88e1-d5d6058d6afc",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "prefix",
                                            "label": "Prefix",
                                            "dataField": "prefix"
                                        },
                                        {
                                            "id": "e432cdc0-d2f4-11eb-88e1-d5d6058d6afc",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "firstname",
                                            "label": "First Name",
                                            "dataField": "firstname"
                                        },
                                        {
                                            "id": "e432cdc2-d2f4-11eb-88e1-d5d6058d6afc",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "lastname",
                                            "label": "Last Name",
                                            "dataField": "lastname"
                                        },
                                        {
                                            "id": "e432cdc4-d2f4-11eb-88e1-d5d6058d6afc",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "email",
                                            "label": "Email",
                                            "dataField": "email"
                                        },
                                        {
                                            "id": "e432cdc6-d2f4-11eb-88e1-d5d6058d6afc",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "contact",
                                            "label": "Contact",
                                            "dataField": "contact"
                                        },
                                        {
                                            "id": "e432cdd2-d2f4-11eb-88e1-d5d6058d6afc",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "status",
                                            "label": "Status",
                                            "dataField": "status"
                                        },
                                        {
                                            "id": "e432cdd4-d2f4-11eb-88e1-d5d6058d6afc",
                                            "type": "column",
                                            "children": [],
                                            "orientation": "horizontal",
                                            "name": "leader",
                                            "label": "Leader",
                                            "dataField": "leader"
                                        }
                                    ],
                                    "orientation": "horizontal",
                                    "name": "table",
                                    "path": "/applicants",
                                    "component": ""
                                }
                            ],
                            "orientation": "horizontal",
                            "component": "ApplicantsTable"
                        }
                    ],
                    "component": ""
                }
            ],
            "orientation": "vertical",
            "name": "applicant-list",
            "route": "/applicants-list",
            "dataModel": "61d6a850-cfda-11eb-891c-1b5caf038a78",
            "component": "",
            "access": ""
        },
        {
            "id": "bad716c0-d2f9-11eb-ac42-ddcf4032daeb",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "bad716c1-d2f9-11eb-ac42-ddcf4032daeb",
                    "component": "AgentsTable",
                    "children": [
                        {
                            "id": "e8dd9ee0-d2f9-11eb-8348-dd0bd767fc37",
                            "type": "subView",
                            "children": [],
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "f61f2ce0-d2f9-11eb-8348-dd0bd767fc37",
                            "type": "table",
                            "children": [
                                {
                                    "id": "fa7fa0d0-d2f9-11eb-8348-dd0bd767fc37",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "prefix",
                                    "label": "Prefix"
                                },
                                {
                                    "id": "0654a680-d2fa-11eb-8348-dd0bd767fc37",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "firstname",
                                    "label": "First Name"
                                },
                                {
                                    "id": "07c80ca0-d2fa-11eb-8348-dd0bd767fc37",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "lastname",
                                    "label": "Last Name"
                                },
                                {
                                    "id": "08bea1a0-d2fa-11eb-8348-dd0bd767fc37",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "email",
                                    "label": "Email"
                                },
                                {
                                    "id": "09b4e880-d2fa-11eb-8348-dd0bd767fc37",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "contact",
                                    "label": "Contact"
                                },
                                {
                                    "id": "275b3d80-d2fa-11eb-8348-dd0bd767fc37",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "status",
                                    "label": "Status"
                                },
                                {
                                    "id": "28740080-d2fa-11eb-8348-dd0bd767fc37",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "leader",
                                    "label": "Leader"
                                }
                            ],
                            "orientation": "horizontal",
                            "path": "/applicants"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "agents-list",
            "route": "/agents-list",
            "dataModel": "61d6a850-cfda-11eb-891c-1b5caf038a78"
        },
        {
            "id": "5c66cfc0-d968-11eb-8bce-d32a5a25fbc1",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "5c66cfc1-d968-11eb-8bce-d32a5a25fbc1",
                    "component": "RequirementsGrid",
                    "children": []
                }
            ],
            "orientation": "vertical",
            "name": "requirements-page",
            "route": "/requirements"
        },
        {
            "id": "5950c400-da56-11eb-b87b-1fe0789c719c",
            "type": "model",
            "children": [
                {
                    "id": "5d23c500-da56-11eb-b87b-1fe0789c719c",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "key"
                },
                {
                    "id": "61322150-da56-11eb-b87b-1fe0789c719c",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "value"
                },
                {
                    "id": "6683d310-da56-11eb-b87b-1fe0789c719c",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "json"
                }
            ],
            "name": "settings"
        },
        {
            "id": "4d935870-da6b-11eb-88b9-3b9c0b34ba62",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "4d935871-da6b-11eb-88b9-3b9c0b34ba62",
                    "component": "QuestionnaireGrid"
                }
            ],
            "orientation": "vertical",
            "name": "questionnaire-page",
            "route": "/questionnaire"
        },
        {
            "id": "7cde5c40-e323-11eb-9475-65731dfc4d64",
            "type": "model",
            "children": [
                {
                    "id": "86e7c9b0-e323-11eb-9475-65731dfc4d64",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "dataModel": "61d6a850-cfda-11eb-891c-1b5caf038a78",
                    "name": "applicant"
                },
                {
                    "id": "9a93f380-e323-11eb-9475-65731dfc4d64",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "state"
                },
                {
                    "id": "b7d15810-e324-11eb-9475-65731dfc4d64",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "applicant_"
                }
            ],
            "name": "answer"
        },
        {
            "id": "241492b0-e37c-11eb-b764-65093bdac39d",
            "type": "model",
            "children": [
                {
                    "id": "6af39410-e37c-11eb-b764-65093bdac39d",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "requirementId"
                },
                {
                    "id": "75e65010-e37c-11eb-b764-65093bdac39d",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "text"
                },
                {
                    "id": "7d3d95d0-e37c-11eb-b764-65093bdac39d",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "file"
                },
                {
                    "id": "fc61cd90-e37c-11eb-b764-65093bdac39d",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "status"
                },
                {
                    "id": "01350080-e37d-11eb-b764-65093bdac39d",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "status_"
                },
                {
                    "id": "046f6b00-e37d-11eb-b764-65093bdac39d",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "name": "applicant",
                    "dataModel": "61d6a850-cfda-11eb-891c-1b5caf038a78"
                },
                {
                    "id": "2d4a0dd0-ee7e-11eb-b626-e99f45d04e7d",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "file_"
                }
            ],
            "name": "requirement"
        },
        {
            "id": "c837dad0-e919-11eb-8322-61ae0e006741",
            "type": "model",
            "children": [
                {
                    "id": "d257dd80-e919-11eb-8322-61ae0e006741",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "title"
                },
                {
                    "id": "da408dd0-e919-11eb-8322-61ae0e006741",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "hyperlink"
                },
                {
                    "id": "e1072c50-e919-11eb-8322-61ae0e006741",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "description"
                }
            ],
            "name": "training"
        },
        {
            "id": "f1d626d0-e919-11eb-8322-61ae0e006741",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "f1d626d1-e919-11eb-8322-61ae0e006741",
                    "children": [
                        {
                            "id": "f1d98230-e919-11eb-8322-61ae0e006741",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f1d98232-e919-11eb-8322-61ae0e006741",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "f1d98233-e919-11eb-8322-61ae0e006741",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f1d98235-e919-11eb-8322-61ae0e006741",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "hyperlink",
                                    "label": "Hyperlink",
                                    "dataField": "hyperlink"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "f1d98236-e919-11eb-8322-61ae0e006741",
                            "type": "container",
                            "children": [
                                {
                                    "id": "f1d98238-e919-11eb-8322-61ae0e006741",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "f1d7fb90-e919-11eb-8322-61ae0e006741",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "training-page",
            "route": "/trainings/:id",
            "dataModel": "c837dad0-e919-11eb-8322-61ae0e006741",
            "access": "noone"
        },
        {
            "id": "f1da93a0-e919-11eb-8322-61ae0e006741",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "f1d626d1-e919-11eb-8322-61ae0e006741",
                    "children": [
                        {
                            "id": "f1dc1a40-e919-11eb-8322-61ae0e006741",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "f1de1610-e919-11eb-8322-61ae0e006741",
                            "type": "table",
                            "children": [
                                {
                                    "id": "f1df75a0-e919-11eb-8322-61ae0e006741",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                },
                                {
                                    "id": "f1df75a2-e919-11eb-8322-61ae0e006741",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "hyperlink",
                                    "label": "Hyperlink",
                                    "dataField": "hyperlink"
                                },
                                {
                                    "id": "f1df75a4-e919-11eb-8322-61ae0e006741",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "training-list",
            "route": "/trainings-list",
            "dataModel": "c837dad0-e919-11eb-8322-61ae0e006741"
        },
        {
            "id": "087d56a0-ee48-11eb-9c0e-49f14822bf3b",
            "type": "model",
            "children": [
                {
                    "id": "0ffb1020-ee48-11eb-9c0e-49f14822bf3b",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "requirementId"
                },
                {
                    "id": "1587f620-ee48-11eb-9c0e-49f14822bf3b",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "file"
                },
                {
                    "id": "266dfb10-ee48-11eb-9c0e-49f14822bf3b",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "title"
                },
                {
                    "id": "3adac6d0-ef3a-11eb-b2e0-4164673e4ef0",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "file_"
                }
            ],
            "name": "downloadable"
        },
        {
            "id": "84e5a2d0-ef31-11eb-a551-7389f799209f",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "84e5a2d1-ef31-11eb-a551-7389f799209f",
                    "children": [
                        {
                            "id": "84f0ed70-ef31-11eb-a551-7389f799209f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "84f0ed72-ef31-11eb-a551-7389f799209f",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "requirementId",
                                    "label": "RequirementId",
                                    "dataField": "requirementId"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 0
                        },
                        {
                            "id": "84f0ed76-ef31-11eb-a551-7389f799209f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "84f0ed78-ef31-11eb-a551-7389f799209f",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "title",
                                    "label": "Name",
                                    "dataField": "title"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 19
                        },
                        {
                            "id": "84f0ed73-ef31-11eb-a551-7389f799209f",
                            "type": "container",
                            "children": [
                                {
                                    "id": "84f0ed75-ef31-11eb-a551-7389f799209f",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "file",
                                    "label": "File",
                                    "dataField": "file",
                                    "basePath": "downloadables"
                                }
                            ],
                            "orientation": "horizontal",
                            "_sortIndex": 10
                        }
                    ]
                },
                {
                    "id": "84ebbd50-ef31-11eb-a551-7389f799209f",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "downloadable-page",
            "route": "/downloadables/:id",
            "dataModel": "087d56a0-ee48-11eb-9c0e-49f14822bf3b",
            "access": "noone"
        },
        {
            "id": "84f5a860-ef31-11eb-a551-7389f799209f",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "84e5a2d1-ef31-11eb-a551-7389f799209f",
                    "children": [
                        {
                            "id": "84f9c710-ef31-11eb-a551-7389f799209f",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "84fde5c0-ef31-11eb-a551-7389f799209f",
                            "type": "table",
                            "children": [
                                {
                                    "id": "8501b650-ef31-11eb-a551-7389f799209f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "requirementId",
                                    "label": "RequirementId",
                                    "dataField": "requirementId"
                                },
                                {
                                    "id": "8501b652-ef31-11eb-a551-7389f799209f",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "file",
                                    "label": "Name",
                                    "dataField": "title"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "downloadable-list",
            "route": "/downloadables-list",
            "dataModel": "087d56a0-ee48-11eb-9c0e-49f14822bf3b"
        },
        {
            "id": "29cb83b0-f869-11eb-8bb8-21722b7ba1b0",
            "type": "model",
            "children": [
                {
                    "id": "4ec32b00-f869-11eb-8bb8-21722b7ba1b0",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "category"
                },
                {
                    "id": "5bebd9d0-f869-11eb-8bb8-21722b7ba1b0",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "category_"
                },
                {
                    "id": "60a80250-f869-11eb-8bb8-21722b7ba1b0",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "title"
                },
                {
                    "id": "636acbd0-f869-11eb-8bb8-21722b7ba1b0",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "description"
                },
                {
                    "id": "6e886860-f869-11eb-8bb8-21722b7ba1b0",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "MM/dd/yyyy",
                    "name": "date"
                },
                {
                    "id": "7365ad70-f869-11eb-8bb8-21722b7ba1b0",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "MM/dd/yyyy",
                    "name": "time"
                },
                {
                    "id": "776e6470-f869-11eb-8bb8-21722b7ba1b0",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "duration"
                },
                {
                    "id": "7acfdef0-f869-11eb-8bb8-21722b7ba1b0",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "zoomId"
                },
                {
                    "id": "7eb7a070-f869-11eb-8bb8-21722b7ba1b0",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "zoomPasscode"
                },
                {
                    "id": "83fef1f0-f869-11eb-8bb8-21722b7ba1b0",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "zoomLink"
                },
                {
                    "id": "cd0e28b0-f888-11eb-9551-9d0229fc705c",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "cover"
                }
            ],
            "name": "webinar"
        },
        {
            "id": "93d66d10-f869-11eb-8bb8-21722b7ba1b0",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "93d66d11-f869-11eb-8bb8-21722b7ba1b0",
                    "children": [
                        {
                            "id": "93da8bc0-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "container",
                            "children": [
                                {
                                    "id": "7baf5160-f888-11eb-9551-9d0229fc705c",
                                    "type": "inputSelect",
                                    "children": [],
                                    "dataField": "category",
                                    "saveObject": true,
                                    "label": "Category",
                                    "options": "[{\"value\": \"pathfinder\", \"label\": \"Pathfinder\"}, {\"value\": \"torch\", \"label\": \"Torch\"}]",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "93da8bc3-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "container",
                            "children": [
                                {
                                    "id": "93da8bc5-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "93da8bc6-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "container",
                            "children": [
                                {
                                    "id": "93da8bc8-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description",
                                    "className": "fullwidth-input",
                                    "multiline": true,
                                    "line": "40",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "93da8bc9-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "container",
                            "children": [
                                {
                                    "id": "93da8bcb-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "date",
                                    "label": "Date",
                                    "dataField": "date",
                                    "format": "MM/dd/yyyy",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "93da8bcc-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "container",
                            "children": [
                                {
                                    "id": "e1d3abe0-0106-11ec-b46d-a165b7865692",
                                    "type": "inputTime",
                                    "children": [],
                                    "dataField": "time",
                                    "label": "Time",
                                    "format": "hh:mm a",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "93da8bcf-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "container",
                            "children": [
                                {
                                    "id": "93da8bd1-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "duration",
                                    "label": "Duration",
                                    "dataField": "duration",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "93da8bd2-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "container",
                            "children": [
                                {
                                    "id": "93da8bd4-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "zoomId",
                                    "label": "Zoom ID",
                                    "dataField": "zoomId",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "93da8bd5-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "container",
                            "children": [
                                {
                                    "id": "93da8bd7-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "zoomPasscode",
                                    "label": "Zoom Passcode",
                                    "dataField": "zoomPasscode",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "93da8bd8-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "container",
                            "children": [
                                {
                                    "id": "93da8bda-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "zoomLink",
                                    "label": "Zoom Link",
                                    "dataField": "zoomLink",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "dc94ebc0-f888-11eb-9551-9d0229fc705c",
                            "type": "container",
                            "children": [
                                {
                                    "id": "dfea3140-f888-11eb-9551-9d0229fc705c",
                                    "type": "inputMedia",
                                    "children": [],
                                    "basePath": "webinars",
                                    "dataField": "cover",
                                    "label": "Cover",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "93d8de10-f869-11eb-8bb8-21722b7ba1b0",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "webinar-page",
            "route": "/webinars/:id",
            "dataModel": "29cb83b0-f869-11eb-8bb8-21722b7ba1b0",
            "access": "noone"
        },
        {
            "id": "93dcaea0-f869-11eb-8bb8-21722b7ba1b0",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "93d66d11-f869-11eb-8bb8-21722b7ba1b0",
                    "children": [
                        {
                            "id": "93de5c50-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "93e00a00-f869-11eb-8bb8-21722b7ba1b0",
                            "type": "table",
                            "children": [
                                {
                                    "id": "93e16990-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "category",
                                    "label": "Category",
                                    "dataField": "category"
                                },
                                {
                                    "id": "93e16992-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                },
                                {
                                    "id": "93e16994-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "description",
                                    "label": "Description",
                                    "dataField": "description"
                                },
                                {
                                    "id": "93e16996-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "date",
                                    "label": "Date",
                                    "dataField": "date",
                                    "date": true
                                },
                                {
                                    "id": "93e16998-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "time",
                                    "label": "Time",
                                    "dataField": "time",
                                    "date": true,
                                    "format": "hh:mm a"
                                },
                                {
                                    "id": "93e1699a-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "duration",
                                    "label": "Duration",
                                    "dataField": "duration"
                                },
                                {
                                    "id": "93e1699c-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "zoomId",
                                    "label": "ZoomId",
                                    "dataField": "zoomId"
                                },
                                {
                                    "id": "93e1699e-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "zoomPasscode",
                                    "label": "ZoomPasscode",
                                    "dataField": "zoomPasscode"
                                },
                                {
                                    "id": "93e169a0-f869-11eb-8bb8-21722b7ba1b0",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "zoomLink",
                                    "label": "ZoomLink",
                                    "dataField": "zoomLink"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "webinar-list",
            "route": "/webinars-list",
            "dataModel": "29cb83b0-f869-11eb-8bb8-21722b7ba1b0"
        },
        {
            "id": "727345b0-ff91-11eb-aff7-15797d9333c9",
            "type": "model",
            "children": [
                {
                    "id": "36567fd0-ffa4-11eb-935b-b9a23a2fa8a5",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "title"
                },
                {
                    "id": "3137a210-ff9d-11eb-ba64-6b8ec701b70f",
                    "type": "field",
                    "children": [],
                    "dataType": "string",
                    "name": "author"
                },
                {
                    "id": "35292790-ff9d-11eb-ba64-6b8ec701b70f",
                    "type": "field:date",
                    "children": [],
                    "dataType": "date",
                    "format": "MM/dd/yyyy",
                    "name": "date"
                },
                {
                    "id": "3e07a990-ff9d-11eb-ba64-6b8ec701b70f",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "body"
                },
                {
                    "id": "485a7c10-ff9d-11eb-ba64-6b8ec701b70f",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "tags"
                },
                {
                    "id": "4b99c890-ff9d-11eb-ba64-6b8ec701b70f",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "photos"
                }
            ],
            "name": "banner"
        },
        {
            "id": "50e27570-ffa4-11eb-935b-b9a23a2fa8a5",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "50e27571-ffa4-11eb-935b-b9a23a2fa8a5",
                    "children": [
                        {
                            "id": "50e6e240-ffa4-11eb-935b-b9a23a2fa8a5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "50e6e242-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "50e6e243-ffa4-11eb-935b-b9a23a2fa8a5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "50e6e245-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "author",
                                    "label": "Author",
                                    "dataField": "author"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "50e6e246-ffa4-11eb-935b-b9a23a2fa8a5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "50e6e248-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "inputDate",
                                    "children": [],
                                    "name": "date",
                                    "label": "Date",
                                    "dataField": "date",
                                    "format": "MM/dd/yyyy"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "50e6e249-ffa4-11eb-935b-b9a23a2fa8a5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "50e70951-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "body",
                                    "label": "Body",
                                    "dataField": "body",
                                    "line": "40",
                                    "multiline": true,
                                    "className": "fullwidth-input"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "50e70952-ffa4-11eb-935b-b9a23a2fa8a5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "50e70954-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "tags",
                                    "label": "Tags (separated by comma)",
                                    "dataField": "tags"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "50e70955-ffa4-11eb-935b-b9a23a2fa8a5",
                            "type": "container",
                            "children": [
                                {
                                    "id": "50e70957-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "photos",
                                    "label": "Photos",
                                    "dataField": "photos",
                                    "basePath": "banners"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "50e4e670-ffa4-11eb-935b-b9a23a2fa8a5",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "banner-page",
            "route": "/banners/:id",
            "dataModel": "727345b0-ff91-11eb-aff7-15797d9333c9",
            "access": "noone"
        },
        {
            "id": "50e88ff0-ffa4-11eb-935b-b9a23a2fa8a5",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "50e27571-ffa4-11eb-935b-b9a23a2fa8a5",
                    "children": [
                        {
                            "id": "50ea1690-ffa4-11eb-935b-b9a23a2fa8a5",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "50eb4f10-ffa4-11eb-935b-b9a23a2fa8a5",
                            "type": "table",
                            "children": [
                                {
                                    "id": "50ecd5b0-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                },
                                {
                                    "id": "50ecd5b2-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "author",
                                    "label": "Author",
                                    "dataField": "author"
                                },
                                {
                                    "id": "50ecd5b4-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "date",
                                    "label": "Date",
                                    "dataField": "date"
                                },
                                {
                                    "id": "50ecd5b6-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "body",
                                    "label": "Body",
                                    "dataField": "body"
                                },
                                {
                                    "id": "50ecd5b8-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "tags",
                                    "label": "Tags",
                                    "dataField": "tags"
                                },
                                {
                                    "id": "50ecd5ba-ffa4-11eb-935b-b9a23a2fa8a5",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "photos",
                                    "label": "Photos",
                                    "dataField": "photos"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "banner-list",
            "route": "/banners-list",
            "dataModel": "727345b0-ff91-11eb-aff7-15797d9333c9"
        },
        {
            "id": "7f93cd20-0110-11ec-922a-21af071e64e2",
            "type": "model",
            "children": [
                {
                    "id": "9181f340-0110-11ec-922a-21af071e64e2",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "firstname"
                },
                {
                    "id": "95163d90-0110-11ec-922a-21af071e64e2",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "lastname"
                },
                {
                    "id": "99f94f00-0110-11ec-922a-21af071e64e2",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "email"
                },
                {
                    "id": "9c3e8410-0110-11ec-922a-21af071e64e2",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "contact"
                },
                {
                    "id": "b0a0de80-0110-11ec-922a-21af071e64e2",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "referred"
                },
                {
                    "id": "c9dcfb90-0110-11ec-922a-21af071e64e2",
                    "type": "field:relation",
                    "children": [],
                    "dataType": "relation",
                    "name": "webinar",
                    "dataModel": "29cb83b0-f869-11eb-8bb8-21722b7ba1b0"
                },
                {
                    "id": "e07e2270-0110-11ec-922a-21af071e64e2",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "status"
                },
                {
                    "id": "e366ea80-0110-11ec-922a-21af071e64e2",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "status_"
                },
                {
                    "id": "e7743560-0110-11ec-922a-21af071e64e2",
                    "type": "field:boolean",
                    "children": [],
                    "dataType": "boolean",
                    "name": "sent"
                },
                {
                    "id": "251381f0-0111-11ec-922a-21af071e64e2",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "webinar_"
                }
            ],
            "name": "participant"
        },
        {
            "id": "851f7140-0129-11ec-aa5f-3ddada80e596",
            "type": "model",
            "children": [
                {
                    "id": "8d66e360-0129-11ec-aa5f-3ddada80e596",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "name"
                },
                {
                    "id": "9080b0c0-04fe-11ec-8d04-3d15fb0558ec",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "title"
                },
                {
                    "id": "974e49e0-0129-11ec-aa5f-3ddada80e596",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "rate"
                },
                {
                    "id": "9d9f4330-0129-11ec-aa5f-3ddada80e596",
                    "type": "field:file",
                    "children": [],
                    "dataType": "file",
                    "collection": "file",
                    "via": "related",
                    "allowedTypes": [
                        "images",
                        "files",
                        "videos"
                    ],
                    "plugin": "upload",
                    "name": "photo"
                },
                {
                    "id": "d21d5af0-0504-11ec-8d04-3d15fb0558ec",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "comments"
                }
            ],
            "name": "testimonial"
        },
        {
            "id": "b117e420-04fe-11ec-8d04-3d15fb0558ec",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "b117e421-04fe-11ec-8d04-3d15fb0558ec",
                    "children": [
                        {
                            "id": "b11c29e0-04fe-11ec-8d04-3d15fb0558ec",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b11c29e2-04fe-11ec-8d04-3d15fb0558ec",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "name",
                                    "label": "Name",
                                    "dataField": "name"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "b11c29e3-04fe-11ec-8d04-3d15fb0558ec",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b11c29e5-04fe-11ec-8d04-3d15fb0558ec",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "b11c29e9-04fe-11ec-8d04-3d15fb0558ec",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b11c29eb-04fe-11ec-8d04-3d15fb0558ec",
                                    "type": "inputMedia",
                                    "children": [],
                                    "name": "photo",
                                    "label": "Photo",
                                    "dataField": "photo",
                                    "basePath": "testimonials"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "d96648d0-0504-11ec-8d04-3d15fb0558ec",
                            "type": "container",
                            "children": [
                                {
                                    "id": "db7bbb50-0504-11ec-8d04-3d15fb0558ec",
                                    "type": "inputText",
                                    "children": [],
                                    "multiline": true,
                                    "line": "12",
                                    "label": "Comments",
                                    "dataField": "comments"
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "b11a5520-04fe-11ec-8d04-3d15fb0558ec",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "testimonial-page",
            "route": "/testimonials/:id",
            "dataModel": "851f7140-0129-11ec-aa5f-3ddada80e596",
            "access": "noone"
        },
        {
            "id": "b11e4cc0-04fe-11ec-8d04-3d15fb0558ec",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "b117e421-04fe-11ec-8d04-3d15fb0558ec",
                    "children": [
                        {
                            "id": "b1204890-04fe-11ec-8d04-3d15fb0558ec",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "b121f640-04fe-11ec-8d04-3d15fb0558ec",
                            "type": "table",
                            "children": [
                                {
                                    "id": "b123cb00-04fe-11ec-8d04-3d15fb0558ec",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "name",
                                    "label": "Name",
                                    "dataField": "name"
                                },
                                {
                                    "id": "b123cb02-04fe-11ec-8d04-3d15fb0558ec",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "title",
                                    "label": "Title",
                                    "dataField": "title"
                                },
                                {
                                    "id": "b123cb04-04fe-11ec-8d04-3d15fb0558ec",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "rate",
                                    "label": "Comments",
                                    "dataField": "comments"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "testimonial-list",
            "route": "/testimonials-list",
            "dataModel": "851f7140-0129-11ec-aa5f-3ddada80e596"
        },
        {
            "id": "b0f071c0-103d-11ec-8abd-bbbf6c0700e6",
            "type": "model",
            "children": [
                {
                    "id": "b9409670-103d-11ec-8abd-bbbf6c0700e6",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "email"
                },
                {
                    "id": "bc1f2550-103d-11ec-8abd-bbbf6c0700e6",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "firstName"
                },
                {
                    "id": "c294e4b0-103d-11ec-8abd-bbbf6c0700e6",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "lastName"
                },
                {
                    "id": "cdbda4d0-103d-11ec-8abd-bbbf6c0700e6",
                    "type": "field:text",
                    "children": [],
                    "dataType": "text",
                    "name": "role"
                },
                {
                    "id": "d62dfbb0-103d-11ec-8abd-bbbf6c0700e6",
                    "type": "field:json",
                    "children": [],
                    "dataType": "json",
                    "name": "role_"
                }
            ],
            "name": "member"
        },
        {
            "id": "1ef51e00-103e-11ec-8abd-bbbf6c0700e6",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "1ef54510-103e-11ec-8abd-bbbf6c0700e6",
                    "children": [
                        {
                            "id": "1efc7100-103e-11ec-8abd-bbbf6c0700e6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1efc7102-103e-11ec-8abd-bbbf6c0700e6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "email",
                                    "label": "Email",
                                    "dataField": "email",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "99ebbb30-152c-11ec-ab8d-db73a3054a4b",
                            "type": "container",
                            "children": [
                                {
                                    "id": "9d0668b0-152c-11ec-ab8d-db73a3054a4b",
                                    "type": "inputText",
                                    "children": [],
                                    "password": true,
                                    "required": false,
                                    "name": "password",
                                    "label": "Password"
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "1efc7103-103e-11ec-8abd-bbbf6c0700e6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1efc7105-103e-11ec-8abd-bbbf6c0700e6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "firstName",
                                    "label": "First Name",
                                    "dataField": "firstName",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "1efc7106-103e-11ec-8abd-bbbf6c0700e6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "1efc7108-103e-11ec-8abd-bbbf6c0700e6",
                                    "type": "inputText",
                                    "children": [],
                                    "name": "lastName",
                                    "label": "Last Name",
                                    "dataField": "lastName",
                                    "required": true
                                }
                            ],
                            "orientation": "horizontal"
                        },
                        {
                            "id": "1efc7109-103e-11ec-8abd-bbbf6c0700e6",
                            "type": "container",
                            "children": [
                                {
                                    "id": "b40b29d0-103e-11ec-977d-99dfde9e851e",
                                    "type": "inputSelect",
                                    "children": [],
                                    "options": "[{\"label\":\"Super Admin\", \"value\":\"admin\"}, {\"label\":\"Admin Marketing\", \"value\":\"marketing\"}, {\"label\":\"Admin Operations\", \"value\":\"operations\"}]",
                                    "label": "Role",
                                    "required": true,
                                    "dataField": "role",
                                    "saveObject": true
                                }
                            ],
                            "orientation": "horizontal"
                        }
                    ]
                },
                {
                    "id": "1efac350-103e-11ec-8abd-bbbf6c0700e6",
                    "type": "subView",
                    "children": [],
                    "view": "84378d60-c21f-11eb-bc09-3fdfcb6a7f06"
                }
            ],
            "orientation": "vertical",
            "name": "members-page",
            "route": "/members/:id",
            "dataModel": "b0f071c0-103d-11ec-8abd-bbbf6c0700e6",
            "access": "noone"
        },
        {
            "id": "1efe1eb0-103e-11ec-8abd-bbbf6c0700e6",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "1ef54510-103e-11ec-8abd-bbbf6c0700e6",
                    "children": [
                        {
                            "id": "1f008fb0-103e-11ec-8abd-bbbf6c0700e6",
                            "type": "subView",
                            "children": [],
                            "name": "searchbar",
                            "view": "84376650-c21f-11eb-bc09-3fdfcb6a7f06"
                        },
                        {
                            "id": "1f023d60-103e-11ec-8abd-bbbf6c0700e6",
                            "type": "table",
                            "children": [
                                {
                                    "id": "1f03eb10-103e-11ec-8abd-bbbf6c0700e6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "email",
                                    "label": "Email",
                                    "dataField": "email"
                                },
                                {
                                    "id": "1f03eb12-103e-11ec-8abd-bbbf6c0700e6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "firstName",
                                    "label": "First Name",
                                    "dataField": "firstName"
                                },
                                {
                                    "id": "1f03eb14-103e-11ec-8abd-bbbf6c0700e6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "lastName",
                                    "label": "Last Name",
                                    "dataField": "lastName"
                                },
                                {
                                    "id": "1f03eb16-103e-11ec-8abd-bbbf6c0700e6",
                                    "type": "column",
                                    "children": [],
                                    "orientation": "horizontal",
                                    "name": "role",
                                    "label": "Role",
                                    "dataField": "role"
                                }
                            ],
                            "orientation": "horizontal",
                            "name": "table"
                        }
                    ]
                }
            ],
            "orientation": "vertical",
            "name": "members-list",
            "route": "/members-list",
            "dataModel": "b0f071c0-103d-11ec-8abd-bbbf6c0700e6"
        },
        {
            "id": "8d28ee10-58b8-11ed-8dc3-21970d5e4dd0",
            "type": "page",
            "children": [
                {
                    "name": "main",
                    "type": "container",
                    "id": "8d28ee11-58b8-11ed-8dc3-21970d5e4dd0",
                    "component": "OnboardingGrid"
                }
            ],
            "orientation": "vertical",
            "name": "onboarding-page",
            "route": "/onboarding"
        }
    ],
    "server": "localhost:1337",
    "undoLength": 10,
    "activeAppName": "dashboard",
    "name": "FWD",
    "_state": {},
    "updateKey": "eb974450-0504-11ec-8d04-3d15fb0558ec",
    "_id": "60b4ffaf865b9a32f8b17d6e",
    "activeApp": "99bf06e0-c21f-11eb-bc09-3fdfcb6a7f06"
};

export { app }

