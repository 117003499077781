import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import config from 'config/config';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

let AutoPlaySwipeableViews = autoPlay(SwipeableViews);
// const TextFieldMemo = React.memo(TextField, (prev, next) => {
// return (
// prev.value === next.value &&
// prev.label === next.label &&
// prev.description === next.description
// );
// });

const StatefulCarousel = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  let node = props.node;
  let fs = props.context;

  let m = {};
  let mI = {};

  let dataField = props.dataField || node.dataField || node.name;
  let modelName = `data.${dataField}`;

  if (fs) {
    if (fs.model) {
      m = { ...fs.model(modelName) };
      mI = (fs.modelInfo ? fs.modelInfo[dataField] : {}) || {};
    }
  }

  let CarouselItem = SwipeableViews;

  let desc = node.description;
  if (m.error) {
    desc = m.message;
  }

  const autoplay = node.autoplay || false;
  const children = node.children;
  const cls = node.className;
  const photos = m.value;
  const maxSteps = photos.length;

  if (Array.isArray(photos)) {
    if (photos.length == 0) {
      photos.push({
        label: 'placeholder',
        url: './assets/images/icon_missing_item.svg',
      });
    }
  }

  if (autoplay) {
    CarouselItem = AutoPlaySwipeableViews;
  }

  node.direction = 'ltr';

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const onItemClick = () => {
    setIsOpen(true);
    document.body.classList.add('unscrollable');
  };

  const onCloseClick = () => {
    setIsOpen(false);
    document.body.classList.remove('unscrollable');
  };

  return (
    <div className={clsx('carousel-main-container', cls)}>
      <Paper square elevation={0}>
        <Typography>{node.label}</Typography>
      </Paper>

      <div className="carousel-container">
        {Array.isArray(photos) && (
          <React.Fragment>
            <CarouselItem
              axis={node.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              className={'carousel-container-item'}
              onClick={onItemClick}
            >
              {photos.map((photo, index) => (
                <div className="carousel-item" key={photo.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <div
                      className="inner"
                      style={{
                        background: `url('${
                          photo.url.startsWith('./')
                            ? photo.url
                            : config.app.server.url + photo.url
                        }') no-repeat center center / ${
                          photo.url.startsWith('./') ? 'contain' : 'cover'
                        }`,
                      }}
                    ></div>
                  ) : null}
                </div>
              ))}
            </CarouselItem>

            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  {node.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {node.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                </Button>
              }
            />
          </React.Fragment>
        )}
      </div>

      {RenderRegistry.renderChildren(children, {
        ...props,
        context: fs,
      })}

      <div
        className={
          isOpen ? 'carousel-fs-container active' : 'carousel-fs-container'
        }
      >
        <IconButton
          onClick={onCloseClick}
          className="carousel-close-button"
          size="small"
        >
          <CloseIcon color="secondary" fontSize="large" />
        </IconButton>
        {Array.isArray(photos) && (
          <React.Fragment>
            <CarouselItem
              axis={node.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              className={'carousel-container-item'}
              onClick={onItemClick}
            >
              {photos.map((photo, index) => (
                <div className="carousel-item" key={photo.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <div
                      className="inner"
                      style={{
                        background: `url('${
                          photo.url.startsWith('./')
                            ? photo.url
                            : config.app.server.url + photo.url
                        }') no-repeat center center / contain`,
                      }}
                    ></div>
                  ) : null}
                </div>
              ))}
            </CarouselItem>

            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  {node.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {node.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                </Button>
              }
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

RenderRegistry.add({
  carousel: StatefulCarousel,
});
