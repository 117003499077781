import React, { Suspense } from 'react';
import { HashRouter as TheRouter } from 'react-router-dom';
import Routes from './Routes';

import './apps';

import 'bootstrap/scss/bootstrap-grid.scss';
import './assets/app_development.scss';
import './assets/_app.css';

import './apps/fwd/fwd';

import theme from './theme';

import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { StoreProvider as AuthProvider } from 'stores/AuthStore';
import { StoreProvider as DomainProvider } from 'stores/DomainStore';
import { StoreProvider as UIProvider } from 'stores/UIStore';
import { StoreProvider as AppProvider } from 'stores/AppStore';
import { StoreProvider as ModalProvider } from 'components/Modal';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function App() {
  return (
    <div className="App">
      {/* prettier-ignore */}
      <ThemeProvider theme={theme}>
      <AppProvider>
      <DomainProvider>
      <AuthProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <UIProvider>
      <ModalProvider>
      <SnackbarProvider autoHideDuration={3000}>
        <TheRouter>
        <Suspense fallback={<div></div>}>
          <Routes />
        </Suspense>
        </TheRouter>
      </SnackbarProvider>
      </ModalProvider>
      </UIProvider>
      </MuiPickersUtilsProvider>
      </AuthProvider>
      </DomainProvider>
      </AppProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
