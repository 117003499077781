import React from 'react';
import path from 'path';
import FileList from './FileList';
import $storage from 'libs/storage';
import { Button } from '@material-ui/core';
export { FileList };

export default function UploadField(props) {
  let value = props.value;
  if (typeof value === 'object' && value.length === undefined) {
    value = [value];
  }

  const base = props.basepath || 'tmp';

  const onUploadSuccess = (files) => {
    if (props.onChange) {
      setTimeout(() => {
        props.onChange({
          target: {
            name: props.model || props.name,
            value: [...value, ...files],
          },
        });
      }, 50);
    }
  };

  const upload = (files) => {
    let p = [];
    Array.prototype.forEach.call(files || [], (file) => {
      p.push(
        $storage.upload(file, {
          path: path.join(base || '', file.name),
          // path: 'tmp/' + file.name,
          onUploadProgress: (p) => {
            console.log(file.name);
            console.log(p);
          },
        })
      );
    });

    Promise.all(p).then((res) => {
      let files = res.map((f) => {
        if (!f || !f.data) {
          return '';
        }
        return f.data.file;
      });

      onUploadSuccess(files);
    });
  };

  const onFileChange = (evt) => {
    upload(evt.target.files);
  };

  return (
    <div className="no-print">
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={`${props.model}-contained-button-file`}
        multiple
        type="file"
        onChange={onFileChange}
      />
      <label htmlFor={`${props.model}-contained-button-file`}>
        <Button variant="contained" color="primary" component="span">
          Upload
        </Button>
      </label>
    </div>
  );
}

/*
export default class UploadField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragOver: false
    };

    this.dragover = this.dragover.bind(this);
    this.dragleave = this.dragleave.bind(this);
    this.drop = this.drop.bind(this);

    this.fileInputRef = React.createRef();
  }

  onFileChange({ target: { files } }) {
    if (!files.length) return;
    this.setState({ files: files });
  }

  upload() {
    let p = [];
    Array.prototype.forEach.call(this.state.files || [], file => {
      p.push(
        this.$storage.upload(file, {
          path: path.join(this.props.base || '', file.name),
          // path: 'tmp/' + file.name,
          onUploadProgress: p => {
            console.log(file.name);
            console.log(p);
          }
        })
      );
    });

    Promise.all(p).then(res => {
      let files = res.map(f => {
        return f.data.path;
      });
      this.setState({
        files: files
      });
      setTimeout(() => {
        this.onUploadSuccess();
      });
    });
  }

  onUploadSuccess() {
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          value: this.state.files
        }
      });
    }
  }

  dragover(evt) {
    this.setState({
      dragOver: true
    });
    evt.preventDefault();
  }

  dragleave(evt) {
    this.setState({
      dragOver: false
    });
    evt.preventDefault();
  }

  drop(evt) {
    this.setState({
      dragOver: false
    });

    evt.preventDefault();

    let files = [];
    if (evt.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < evt.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (evt.dataTransfer.items[i].kind === 'file') {
          var file = evt.dataTransfer.items[i].getAsFile();
          files.push(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < evt.dataTransfer.files.length; i++) {
        var file = evt.dataTransfer.files[i];
        files.push(file);
      }
    }

    this.onFileChange({
      target: {
        files: files
      }
    });

    setTimeout(() => {
      this.upload();
    });
  }

  render() {
    let extra = this.state.dragOver ? 'bg-secondary' : '';
    return (
      <Fragment>
        <input type="file" onChange={this.onFileChange}/>
        <div
          className={`card ${extra} mt-4 mb-4 p-2`}
          onDragOver={this.dragover}
          onDragLeave={this.dragleave}
          onDrop={this.drop}
        >
          <div
            className="p-4 text-center"
            style={{ borderStyle: 'dashed', color: '#c0c0c0' }}
          >
            <i
              className="fa fa-cloud-upload display-4"
              style={{ color: 'orange' }}
            ></i>
            <br />

            {!(this.state.files && this.state.files.length) && (
              <span className="">Drag-and-Drop files here.</span>
            )}

            {this.state.files && this.state.files.length && (
              <span className="">Uploading now ...</span>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
*/
