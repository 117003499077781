import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt();

const Paragraph = (props) => {
  let node = props.node;
  let content = node.text;

  if (props.context) {
    let model = node.dataField || node.name;
    if (model) {
      content = props.context.getState(`data.${model}`) || content;
    }
  }

  let contentRenderered = md.render(content || '');

  let _props = { ...props };
  delete _props.staticContext;

  return (
    <p {..._props} dangerouslySetInnerHTML={{ __html: contentRenderered }}></p>
  );
};

RenderRegistry.add({
  paragraph: Paragraph,
});
