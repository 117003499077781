import React from 'react';
import RenderRegistry from '../RenderRegistry';
import { useModal } from '../../../components/Modal';
import { Button } from '@material-ui/core';
import crud from 'libs/crud';
import cache from 'libs/cache.js';

const LoginNotice = (props) => {
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const cx = context;

  let res = {};

  const { showModal, closeModal } = useModal();

  const onAccept = () => {
    const h = crud('homeowners');
    try {
      if (res) {
        if (res.data) {
          res.data.noticeShown = true;
        }
      }
      console.log(res);
      h.save(res.data);
    } catch (err) {}
    closeModal();
  };

  const showNotice = () => {
    showModal({
      title: 'Data Privacy Notice',
      message: (
        <div>
          <div className="terms-conditions-list p-3">
            <p className="mb-2">
              The use of this application is in full compliance with{' '}
              <b>R.A. 10173</b> or the <b>Data Privacy Act of 2012</b>. Upon
              your access to this application, Pacific Land may require you to
              provide necessary personal and sensitive personal information.
            </p>
            <p>
              By clicking <b>PROCEED</b>, you consent to and authorize Pacific
              Land to process your personal and sensitive personal information,
              pursuant to the Data Privacy Act of 2012 and applicable laws.
            </p>
          </div>
          <Button
            className="mt-3"
            variant="contained"
            color="primary"
            fullWidth
            onClick={onAccept}
          >
            I agree and proceed
          </Button>
        </div>
      ),
      actions: [],
    });
  };

  const getHomeowner = async () => {
    const h = crud('homeowners/me');
    try {
      res = await h.find({});
      if (res) {
        if (res.data) {
          if (!res.data.noticeShown) {
            showNotice();
          }

          cache.put('homeowner', res.data, {
            persist: true,
          });
        }
      }
    } catch (err) {}
  };

  React.useEffect(() => {
    getHomeowner();
  }, []);

  return (
    <div>
      {RenderRegistry.renderChildren(node.children, { ...props, context: cx })}
    </div>
  );
};

RenderRegistry.add({
  LoginNotice,
});
