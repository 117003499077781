// import { faAngleDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft';
// import { faAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleRight';
// import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
// import { faArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp';
// import { faArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown';
// import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
// import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';

// export const library = {
//   add: (icon, name) => {
//     library[icon.iconName] = icon;
//     if (name) {
//       library[name] = icon;
//     }
//   },
// };

// library.add(faTrash, 'faTrash');
// library.add(faPlus, 'faPlus');
// library.add(faAngleDoubleLeft, 'faAngleDoubleLeft');
// library.add(faAngleDoubleRight, 'faAngleDoubleRight');
// library.add(faBars, 'faBars');
// library.add(faArrowUp, 'faArrowUp');
// library.add(faArrowDown, 'faArrowDown');

export const library = {};
