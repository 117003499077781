import React from 'react';
import RenderRegistry from '../common/RenderRegistry';
import StateHelper from 'libs/stateHelper';
import crud from 'libs/crud';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { Receipt } from '@material-ui/icons';

const cx = new StateHelper();

const ApplicantsTable = (props) => {
  const [state, setState] = React.useState({ data: [] });
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const n = crud('applicants');

  cx.useState(state, setState);

  const getApplicants = async () => {
    let res = await n.find({
      'or:': [{ status: 'applicant' }, { status: 'in-process' }],
      _sort: 'createdAt:DESC',
    });
    if (res) {
      if (res.data) {
        cx.setState({ data: res.data });
      }
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      getApplicants();
    }, 800);
  }, []);

  return (
    <React.Fragment>
      {RenderRegistry.renderChildren(node.children, {
        ...props,
        context: cx,
      })}
    </React.Fragment>
  );
};

const AgentsTable = (props) => {
  const [state, setState] = React.useState({ data: [] });
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const n = crud('applicants');

  cx.useState(state, setState);

  const getApplicants = async () => {
    let res = await n.find({ status: 'agent', _sort: 'createdAt:DESC' });
    if (res.data) {
      cx.setState({ data: res.data });
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      getApplicants();
    }, 800);
  }, []);

  return (
    <React.Fragment>
      {RenderRegistry.renderChildren(node.children, {
        ...props,
        context: cx,
      })}
    </React.Fragment>
  );
};

const LeaderPicker = (props) => {
  const { node, context } = props;
  const [leader, setLeader] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const loading = open && leader.length === 0;
  const n = crud('applicants');

  const getAgents = async () => {
    let res = await n.find({ status: 'agent', _sort: 'createdAt:DESC' });
    if (res.data) {
      if (res.data.length > 0) {
        const _agents = [];
        for (const a of res.data) {
          _agents.push({ label: `${a.firstname} ${a.lastname}`, value: a._id });
        }
        setLeader(_agents);
      }
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      getAgents();
    }, 800);
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setLeader([]);
    }
  }, [open]);

  const handleDataChange = async (val) => {
    if (val) {
      context.validate('leader', val);
      context.setState({
        data: {
          ...context['_state'].data,
          leader: val.value,
          leader_: val,
        },
      });
    }
  };
  return (
    <div className="form-label" style={{ flex: 1, margin: '4px' }}>
      <Typography variant="h6">{node.label}</Typography>
      <Autocomplete
        key={`leader-options-${leader.length}`}
        value={context.getState('data.leader_')}
        id="combo-box-demo"
        onChange={(event, newValue) => {
          handleDataChange(newValue);
        }}
        options={leader}
        getOptionLabel={(option) => option.label}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        getOptionSelected={(option, value) => option.value === value.value}
      />
    </div>
  );
};

const PositionPicker = (props) => {
  const { node, context } = props;
  const state = context.getState(`data`);
  const value = state.position || '';
  const s = state.status || '';
  const leaderId = state.leader;

  const opts = [
    { label: 'Admin', value: 1 },
    { label: 'Director', value: 2 },
    { label: 'Branch Manager', value: 3 },
    { label: 'AFWM', value: 4 },
    { label: 'Unit Manager (UM)', value: 5 },
    { label: 'AFWO', value: 6 },
    { label: 'Planner (FWP)', value: 7 },
  ];

  const [options, setOptions] = React.useState([...opts]);

  const n = crud('applicants');

  const getLeader = async () => {
    if (leaderId) {
      let res = await n.find({ 'oid:_id': leaderId });
      if (res) {
        if (res.data) {
          if (res.data.length > 0) {
            const leadPos = res.data[0].position || 0;
            const opt = opts.filter((o) => {
              return o.value > leadPos;
            });
            setOptions(opt);
          }
        }
      }
    }
  };

  React.useEffect(() => {
    getLeader();
  }, [state]);

  const handleChange = (val) => {
    const idx = options.findIndex((o) => o.value === val.target.value);
    context.validate('position', val.target.value);
    context.setState({
      data: {
        ...context['_state'].data,
        position: val.target.value,
        position_: options[idx],
      },
    });
  };

  return s == 'agent' ? (
    <div className="form-label" style={{ flex: 1, margin: '4px' }}>
      <Typography variant="h6">{node.label}</Typography>
      <TextField
        fullWidth
        variant="outlined"
        onChange={(e) => handleChange(e)}
        value={value}
        select
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {/* <pre>{JSON.stringify(leader, null, 4)}</pre> */}
    </div>
  ) : null;
};

RenderRegistry.add({
  ApplicantsTable,
  AgentsTable,
  LeaderPicker,
  PositionPicker,
});
