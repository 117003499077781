import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import events from 'libs/events';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import { findById } from 'libs/utility';
import DataModel from '../data/DataModel';
import debounce from 'debounce';
import pluralize from 'pluralize';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

const cachedContext = {};
const nullDataModel = new DataModel({});

const SubView = withRouter((props) => {
  const app = useApp();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = React.useState({
    data: {},
  });

  let node = findById(app.state, props.node.id, { key: 'id' }) || {};
  let name = node.name || node.id;

  let subView;
  let project = app.state;

  let parentContext = props.context;
  let viewNode = node;
  let relationField = viewNode.relation || '';
  let relationId = parentContext ? parentContext.getState('data.id') : '';

  (project.children || []).forEach((c) => {
    if (c.type === 'view' && c.id === node.view) {
      subView = c;
      node = subView;
    }
  });

  const renderChildren = RenderRegistry.renderChildren;

  let context;
  let routePath;

  let model = node.dataModel;
  let modelName = 'data';
  let modelDef;
  if (model) {
    modelDef = (app.state.children || []).filter((c) => {
      return c.type === 'model' && c.id === model;
    })[0];
  }

  if (relationField) {
    if (model) {
      modelDef = (app.state.children || []).filter((c) => {
        return c.type === 'model' && c.id === model;
      })[0];
    }

    if (modelDef) {
      context = cachedContext[node.id] || new DataModel(modelDef);
      cachedContext[node.id] = context;

      context.useState(state, setState);
      routePath = pluralize(modelDef.name);
    }
  } else {
    context = parentContext;
  }

  // todo validation

  React.useEffect(() => {
    if (relationField && !relationId) {
      return;
    }

    if (model && !state.data._id) {
      if (parentContext && viewNode && viewNode.relation) {
        fetchData();
      }
    }
  }, [model, relationField, relationId]);

  const fetchData = async (params) => {
    /*
    if (props.match) {
      params = {
        ...props.match.params,
        ...params,
      };

      if (params.id === '0') {
        delete params.id;
        context.setState({
          data: {},
        });
        return;
      }
    }
    */

    params = params || {};
    params.filter = {
      ...(params.filter || {}),
      [`oid:${relationField}`]: relationId,
    };

    if (params && params.filter && modelDef) {
      let filter = { ...params.filter };
      delete params.filter;

      if (filter.search) {
        modelDef.children.forEach((c) => {
          if (c.type.indexOf('field') === 0) {
            if (c.dataType == 'relation') {
              params[`or:${c.name}__regex`] = filter.search;
            } else {
              params[`or:${c.name}_regex`] = filter.search;
            }
          }
        });
        delete filter.search;
      }

      params = { ...params, ...filter };
    }

    context
      .find(params)
      .then((res) => {
        context.setState({
          [`data`]: res.data,
        });
      })
      .catch((err) => {
        enqueueSnackbar('error loading', { variant: 'error' });
      });
  };

  const onFilter = debounce(async (params) => {
    fetchData({
      filter: params,
    });
  }, 50);

  const onNew = async (params) => {
    console.log('field:' + relationField);
    console.log('rel:' + relationId);
    if (!relationField || !relationId) {
      return;
    }

    let newItem = {
      [relationField]: relationId,
    };

    context.crud.save(newItem).then((res) => {
      fetchData();
    });

    /*
      console.log('new item...........');
      console.log(node);
      console.log(params);
      console.log(newItem);
      console.log(relationField);
    */
  };

  const onOpen = async (params) => {};

  const onSave = async (params) => {
    if (!relationField || !relationId) {
      return;
    }

    /*
      console.log('save item...........');
      console.log(node);
      console.log(params);
      console.log(relationField);
      */

    await context.validateState();
    if (context.hasErrors()) {
      return;
    }

    try {
      let res = await context.save();

      // context.setState({
      // data: res.data,
      // });

      // let p = props.match.path.replace(':id', res.data.id);
      // props.history.replace(p);

      // enqueueSnackbar('saved', { variant: 'success' });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('error saving item', { variant: 'error' });
    }
  };

  const onDelete = async (params) => {
    if (!relationField || !relationId || !context) {
      return;
    }

    console.log('delete item...........');
    console.log(context.state());

    let dataRow = context.getState(`data.${params.row}`);
    let deleteItem = dataRow._id;
    context.crud.erase(deleteItem).then((res) => {
      fetchData();
    });

    // await context.erase();
    // props.history.replace(`/${routePath}-list`);
  };

  const subViewEvents = {
    openItem: onOpen,
    newItem: onNew,
    // saveItem: onSave,
    deleteItem: onDelete,
    save: onSave,
    delete: onDelete,
    filterItem: onFilter,
  };

  React.useEffect(() => {
    // todo ... disable other subviews without relationId... relationId has
    if (relationId) {
      console.log('register ' + relationId);
      events.register(subViewEvents);
    }
    return () => {
      console.log('deregister ' + relationId);
      events.unregister(subViewEvents);
    };
  }, [model, relationField, relationId]);

  let customClass = 'bs-' + (name || '').replace(/\s/g, '-');
  let className = node.className || '';

  if (relationField && !relationId) {
    return <div></div>;
  }

  return (
    <div className={clsx('app-sub-view', customClass, className)}>
      {RenderRegistry.renderChildren(node.children, {
        // ...props,
        context,
      })}
    </div>
  );
});

RenderRegistry.add({
  subView: SubView,
});
